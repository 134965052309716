import utils from './utils/utils';

// let CRUDOOL_SERVER_BASE_URL_DEPRECATED=;

function makeSureSlashAsSuffix(baseUrl: string): string {
  return baseUrl[baseUrl.length - 1] == '/' ? baseUrl : baseUrl + '/';
}

/**
 * For this to work run the following command line in Windows:
 *
 *   set REACT_APP_SERVER_CRUDOOL_BASE_URL_DEPRECATED=https://test-magicformula.herokuapp.com
 *   set REACT_APP_SERVER_TRACKER_BASE_URL=https://test-magicformula.herokuapp.com
 *
 *  */
let CRUDOOL_SERVER_BASE_URL_DEPRECATED = makeSureSlashAsSuffix(
  process.env.REACT_APP_SERVER_CRUDOOL_BASE_URL_DEPRECATED || ''
);

let CRUDOOL_SERVER_BASE_URL = makeSureSlashAsSuffix(process.env.REACT_APP_SERVER_CRUDOOL_BASE_URL || '');

let TRACKER_SERVER_BASE_URL = makeSureSlashAsSuffix(process.env.REACT_APP_SERVER_TRACKER_BASE_URL || '');
/* */

if (typeof Storage !== 'undefined' && localStorage.getItem('lh')) {
  CRUDOOL_SERVER_BASE_URL_DEPRECATED = 'http://localhost:5000/';
  CRUDOOL_SERVER_BASE_URL = 'http://localhost:5000/';
  TRACKER_SERVER_BASE_URL = 'http://localhost:5001/';
}

utils.debugLog(
  `NODE_ENV=${process.env.NODE_ENV}\n` +
    `CRUDOOL_SERVER_BASE_URL_DEPRECATED=${CRUDOOL_SERVER_BASE_URL_DEPRECATED}\n` +
    `CRUDOOL_SERVER_BASE_URL=${CRUDOOL_SERVER_BASE_URL}\n` +
    `TRACKER_SERVER_BASE_URL=${TRACKER_SERVER_BASE_URL}`
);

export { CRUDOOL_SERVER_BASE_URL_DEPRECATED, TRACKER_SERVER_BASE_URL, CRUDOOL_SERVER_BASE_URL };
