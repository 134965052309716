import * as React from 'react';

import { TRACKER_SERVER_BASE_URL } from '../../constants';
import Firebase from '../../Firebase';
import Loader from '../../loaderImg.gif';
import { Table } from '../../Table';
import utils from '../../utils/utils';

interface IIncomingPostback {
  loaderVisibility: any;
  results: any;
  tableVisibility: any;
  columns: any;
}

class IncomingPostBacks extends React.Component<any, IIncomingPostback> {
  constructor(props: IIncomingPostback) {
    super(props);
    this.state = {
      columns: [],
      loaderVisibility: true,
      tableVisibility: false,
      results: [],
    };
  }
  componentDidMount() {
    this.incomingPostbackData();
  }
  row: string[] = [];
  COLUMNS = [
    { label: 'Timestamp', key: 'createdAt' },
    {
      label: 'Our ClickID',
      type: 'string',
      isTableKey: true,
      key: 'ourCID',
      filterable: true,
    },
    {
      label: 'Adv indication',
      width: '100',
      type: 'string',
      key: 'postbackOrigin',
      filterable: true,
    },
    {
      label: 'Payout',
      type: 'string',
      width: '75',
      filterable: true,
      key: 'payout',
    },
    {
      label: 'Currency',
      width: '75',
      type: 'string',
      filterable: true,
      key: 'currency',
    },
    {
      label: 'Goal',
      width: '75',
      type: 'string',
      filterable: true,
      key: 'postbackTxid',
    },
    {
      label: 'Geo',
      width: '75',
      type: 'string',
      filterable: true,
      key: 'geo',
    },

    {
      label: 'IsTest',
      width: '75',
      type: 'string',
      filterable: true,
      key: 'test',
    },
    {
      label: 'Payback Parameters',
      type: 'obj',
      filterable: true,
      key: (cell: any, row: any) => <pre>{JSON.stringify(row.everything_for_debug, null, 2)}</pre>,
    },
  ];

  async incomingPostbackData() {
    const token = await Firebase.getUserToken();
    try {
      const response = await fetch(TRACKER_SERVER_BASE_URL + 'incomingPostbackDetails', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      const responseJson = await response.json();
      if (responseJson.error) throw responseJson.error;
      for (const key in responseJson) {
        const obj = responseJson[key];
        obj.payout = obj.everything_for_debug['payout'];
        obj.currency = obj.everything_for_debug['currency'];
        obj.geo = obj.everything_for_debug['geo'];
        obj.test = obj.everything_for_debug['test'];
        obj.createdAt = obj.createdAt.replace('Z', ' ').replace('T', ' ');
        this.row.push(obj);
      }

      this.setState({
        columns: this.COLUMNS,
        results: this.row,
        loaderVisibility: false,
        tableVisibility: true,
      });
    } catch (error) {
      utils.consoleError(`readableDate - Error: ${utils.convertToString(error)}`);
      alert('Error retrieving data from server!');
    }
  }

  render() {
    return (
      <div>
        <br></br>
        <h4>Incoming Postbacks</h4>
        {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
        {this.state.tableVisibility ? (
          <div style={tblDiv}>
            <div></div>
            <Table columns={this.state.columns} rows={this.state.results} hasTotalRow={false} />
          </div>
        ) : null}
      </div>
    );
  }
}

// const styleLogoImg = {
//   width: 160,
//   height: '100%'
// }

// const styleRefresh = {
//   marginTop: 25,
//   marginRight: 0,
//   cursor: 'pointer',
//   zIndex: 999
// }

// const styleDateText = {
//   marginTop: 5,
// }

// const styleText = {
//   marginTop: 20,
// }

const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const tblDiv = {
  width: '95%',
  marginTop: '5%',
  marginLeft: '2.5%',
};

// const styleToolbar = {
//   marginTop: -30
// }
export default IncomingPostBacks;
