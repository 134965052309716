import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';
import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import Firebase from './Firebase';
import SIDEBAR from './Sidebars';
import Toolbar from 'material-ui/Toolbar';
import Logo from './logo_app.png';
import { CRUDOOL_SERVER_BASE_URL_DEPRECATED } from './constants';
import { calcUserScreenState } from './AuthorizationRoles';

interface ICurrentProps {}
interface ICurrentState {
  stateIsAbleToSee: boolean;
  dashboardHtml: string;
  user: firebase.User;
}
export class UserManagement extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);

    this.state = {
      stateIsAbleToSee: false,
      dashboardHtml: 'Loading...',
      user: Firebase.getCurrentUser(),
    };

    /*TODO: CHECK IF WE CAN MAKE IT COMMON FOR ALL PAGES */
    calcUserScreenState().then(data => {
      if (!data.pages.userManagement) {
        // navigate('/');
      } else {
        this.setState({
          stateIsAbleToSee: true,
        });
      }
    });
    this.getFBDashboard();
  }

  getFBDashboard = () => {
    return Firebase.getUserToken().then(token => {
      return fetch(`${CRUDOOL_SERVER_BASE_URL_DEPRECATED}UserManagement`, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => response.text())
        .then(responseJson => {
          // console.log(responseJson);
          this.setState({
            dashboardHtml: responseJson,
          });
        })
        .catch(error => {
          console.error(error);
          this.setState({
            dashboardHtml: error,
          });
        });
    });
  };

  render = () => {
    if (this.state.user && this.state.stateIsAbleToSee) {
      return (
        <div>
          <Helmet>
            <title>Admin Users</title>
          </Helmet>
          <MuiThemeProvider>
            <div id="div">
              <SIDEBAR />
              <Toolbar style={styleToolbar}>
                <img src={Logo} style={styleLogoImg} alt="logo" />
                <ReactTooltip />

                <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => Firebase.logout()} />
              </Toolbar>
            </div>
          </MuiThemeProvider>
          <div>
            <iframe
              srcDoc={this.state.dashboardHtml}
              title="users"
              style={{
                height: '100%',
                width: '100%',
                position: 'absolute',
              }}
            ></iframe>
          </div>
        </div>
      );
    } else {
      return <div>Loading</div>;
    }
  };
}

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};

const styleNotFound = {
  width: '50%',
  height: 300,
  marginLeft: 300,
  marginTop: 20,
};

const styleToolbar = {
  marginTop: -30,
};
