import React from 'react';
import Firebase from './Firebase';
import { Table, IColumn, ITable } from './Table';
import Loader from './loaderImg.gif';
import Logo from './logo_app.png';
import { FaUndo } from 'react-icons/fa';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import TextField from 'material-ui/TextField';
import Toolbar from 'material-ui/Toolbar';
import SIDEBAR from './Sidebars';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TsCampaign from './TsCampaign';
import { CRUDOOL_SERVER_BASE_URL_DEPRECATED } from './constants';
import ServerErrorIcon from './cross.png';
import DebounceCredit from './debounceCredit';
import Breadcrumb, { IBreadcrumb } from './breadcrumb';
import { ITarget } from './TsTargetDailyStatsTable';

interface ICurrentProps {
  match: {
    params: {
      tSId: string;
      id: string;
    };
  };
}

interface ICurrentRow {
  childrenBanners: ITable<unknown>;
  childrenTargets: ITable<ITarget>;
}

interface ICurrentState {
  time: number;
  loaderVisibility: boolean;
  tableVisibility: boolean;
  breadcrumbs: IBreadcrumb[];
  fromDateResponse: string;
  toDateResponse: string;
  volCampaignHeadLink: string;
  volCampaignHeadText: string;
  columns: IColumn[];
  rows: unknown[];
  tsCampaigns: ITable<ICurrentRow>;
  serverError: boolean;
}

export default class VolCampaignSpecialPage extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      columns: [],
      rows: [],
      loaderVisibility: true,
      tableVisibility: false,
      volCampaignHeadLink: '',
      volCampaignHeadText: '',
      tsCampaigns: {
        rows: [],
        columns: [],
      },
      serverError: false,
      time: Date.now(),
      breadcrumbs: [],
      fromDateResponse: '',
      toDateResponse: '',
    };
  }

  handleLogout() {
    Firebase.logout();
  }

  handleRefresh() {
    const fromDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-from'
    ) as HTMLInputElement | null;
    const toDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-to'
    ) as HTMLInputElement | null;
    const fromDate: string = (fromDateElement && fromDateElement.value) || '';
    const toDate: string = (toDateElement && toDateElement.value) || '';
    this.loadTSData(fromDate, toDate, this.props.match.params.tSId, this.props.match.params.id);
    this.setState({
      time: Date.now(),
    });
  }

  loadTSData(fromDate: string, toDate: string, item1: string, item2: string) {
    this.setState({
      loaderVisibility: true,
      tableVisibility: false,
    });
    if (fromDate === '' || toDate === '') {
      alert('From & To date cannot be empty!');
      return;
    }

    return Firebase.getUserToken().then(token => {
      return fetch(
        CRUDOOL_SERVER_BASE_URL_DEPRECATED +
          'dashboard/performance/special1/' +
          item1 +
          '/' +
          item2 +
          '?fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.error) throw responseJson.error;
          for (let i = 0; i < responseJson.breadcrumbs.length; i++) {
            this.setState({
              volCampaignHeadText: responseJson.breadcrumbs[i].display,
              volCampaignHeadLink: responseJson.breadcrumbs[i].link,
            });
          }
          const resultOutput = [];
          const breadcrumbOutput = [];
          for (let x = 0; x < responseJson.breadcrumbs.length; x++) {
            breadcrumbOutput.push(responseJson.breadcrumbs[x]);
          }
          for (let i = 0; i < responseJson.voluumCampaign.rows.length; i++) {
            resultOutput.push(responseJson.voluumCampaign.rows[i]);
          }

          this.setState({
            columns: responseJson.voluumCampaign.columns,
            rows: resultOutput,
            tsCampaigns: responseJson.childrenTsCampaigns,
            loaderVisibility: false,
            tableVisibility: true,
            serverError: false,
            fromDateResponse: responseJson.fromDate,
            toDateResponse: responseJson.toDate,
            breadcrumbs: breadcrumbOutput,
          });
        })
        .catch(error => {
          console.error(error);
          alert('Error retrieving data from server!');
          this.setState({
            serverError: true,
            loaderVisibility: false,
          });
        });
    });
  }

  componentDidMount() {
    const today = new Date();
    const tmpThisMonth = today.getMonth() + 1;
    const thisMonth = tmpThisMonth < 10 ? '0' + tmpThisMonth : tmpThisMonth;
    const tmpThisDate = today.getDate();
    const thisDate = tmpThisDate < 10 ? '0' + tmpThisDate : tmpThisDate;

    const fromDate = today.getFullYear() + '-' + thisMonth + '-01T00:00';
    const toDate = today.getFullYear() + '-' + thisMonth + '-' + thisDate + 'T23:59';

    const fromDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-from'
    ) as HTMLInputElement | null;
    const toDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-to'
    ) as HTMLInputElement | null;
    if (fromDateElement) fromDateElement.value = fromDate;
    if (toDateElement) toDateElement.value = toDate;

    this.loadTSData(fromDate, toDate, this.props.match.params.tSId, this.props.match.params.id);
  }

  render() {
    return (
      <div>
        <MuiThemeProvider>
          <div id="div">
            <SIDEBAR />
            <Toolbar style={styleToolbar}>
              <img src={Logo} style={styleLogoImg} alt="logo" />
              <h3>Traffic Sources - Special</h3>
              <FaUndo data-tip="Refresh" style={styleRefresh} onClick={event => this.handleRefresh()} />
              <ReactTooltip />
              <label style={styleText}>From:</label>
              <TextField style={styleDateText} id="datetime-local-from" type="datetime-local" />
              <label style={styleText}>To:</label>
              <TextField style={styleDateText} id="datetime-local-to" type="datetime-local" />

              <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => this.handleLogout()} />
            </Toolbar>
            {this.state.tableVisibility ? <DebounceCredit time={this.state.time} /> : null}
          </div>
        </MuiThemeProvider>
        {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
        {this.state.tableVisibility ? (
          <div style={tblDiv}>
            <div>
              <Breadcrumb
                breadcrumbs={this.state.breadcrumbs}
                fromDateResponse={this.state.fromDateResponse}
                toDateResponse={this.state.toDateResponse}
              />
            </div>
            <a href={this.state.volCampaignHeadLink} rel="noopener noreferrer" target="_blank">
              {this.state.volCampaignHeadText}
            </a>
            <Table columns={this.state.columns} rows={this.state.rows} />
            <p>Traffic Source (TS) Campaigns:</p>
            {this.state.tsCampaigns.rows.map((result, index) => {
              return (
                <TsCampaign /*tsCampaignData={result}*/
                  tsCampaignDataBanner={this.state.tsCampaigns.rows[index].childrenBanners}
                  tsCampaignDataTargets={this.state.tsCampaigns.rows[index].childrenTargets}
                  // tsCampaignDataNext={this.state.tsCampaigns}
                  tsCampaignDataFull={this.state.tsCampaigns}
                  counter={index}
                  key={index}
                />
              );
            })}
          </div>
        ) : null}
        {this.state.serverError ? <img src={ServerErrorIcon} style={styleServerErrorImg} alt="ServerError" /> : null}
      </div>
    );
  }
}

const tblDiv = {
  width: '95%',
  marginTop: '5%',
  marginLeft: '2.5%',
  zIndex: -999,
};

const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};

const styleDateText = {
  marginTop: 5,
};

const styleText = {
  marginTop: 20,
};

const styleToolbar = {
  marginTop: -30,
};

const styleServerErrorImg = {
  width: '50%',
  height: 300,
  marginLeft: 300,
  marginTop: 20,
};
