import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';

import Firebase from '../Firebase';
import utils from '../utils/utils';
import '../App.css';
import Logo from '../logo_app.png';

interface ICurrentProps {
  history?: string[];
  isLoggedInButNotVerified?: boolean;
}

interface ICurrentState {
  email: string;
  password: string;
  errorMsg: string;
  isForgotPasswordLayout: boolean;
}

export default class Login extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);

    this.state = {
      email: '',
      password: '',
      errorMsg: '',
      isForgotPasswordLayout: false,
    };
  }

  componentDidMount(): void {
    if (Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified) {
      // console.log(Firebase.getCurrentUser().emailVerified);
      utils.consoleLog(`this.props.history=${utils.convertToString(this.props.history)}`);
      this.props.history && this.props.history.push('/');
    } else if (this.props.isLoggedInButNotVerified) {
      const lastEmailVerification = utils.getCache('lastEmailVerification', 5 * 60 * 1000);

      if (lastEmailVerification && !lastEmailVerification.isExpired) {
        this.setState({
          errorMsg:
            'Email address has not been verified! (last verification email was sent on ' +
            lastEmailVerification.timestamp.toLocaleString() +
            ')',
        });
      } else {
        Firebase.resendEmailVerification()
          .then(() => {
            utils.setCache('lastEmailVerification', new Date().toISOString());
            this.setState({
              errorMsg:
                "Email address has not been verified! (we've just re-sent another verification email, please check your inbox or spam folder)",
            });
          })
          .catch(() => {
            // Do nothing.
          });
      }
    }
  }

  handleClick(event: React.KeyboardEvent<unknown> | React.MouseEvent<unknown, MouseEvent>): void {
    if (event.type === 'click' || ('key' in event && event.key === 'Enter')) {
      this.setState({
        errorMsg: '',
      });
      if (!this.state.email || (!this.state.isForgotPasswordLayout && !this.state.password)) {
        this.setState({
          errorMsg: this.state.isForgotPasswordLayout ? 'Email cannot be empty!' : 'Email or Password cannot be empty!',
        });
        event.preventDefault();
        return;
      }

      try {
        if (this.state.isForgotPasswordLayout) {
          Firebase.sendResetPasswordEmail(this.state.email).then(() => {
            alert(`Please check your inbox for further instructions`);
            this.setState({
              isForgotPasswordLayout: false,
            });
          });
        } else {
          Firebase.login(this.state.email, this.state.password)
            .then(() => {
              utils.consoleLog(`this.props.history=${utils.convertToString(this.props.history)}`);
              this.props.history && this.props.history.push('/');
            })
            .catch(error => {
              this.setState({
                errorMsg: error.message,
              });
            });
        }
      } catch (err2) {
        console.error('ERROr2: ' + utils.convertToString(err2));
        this.setState({
          errorMsg: '' + ((err2 && (err2 as { message?: string }).message) || 'Unknown error 749'),
        });
      }
    }
  }

  render(): JSX.Element {
    return (
      <div style={{ textAlign: 'center' }}>
        <MuiThemeProvider>
          <div>
            <AppBar title="Login" />
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={Logo} style={styleLogoImg} alt="logo"></img>
            <br />
            <TextField
              hintText="Enter your Email"
              floatingLabelText="Email"
              onChange={(event, newValue) => this.setState({ email: newValue, errorMsg: '' })}
              onKeyDown={event => this.handleClick(event)}
            />
            <br />
            {!this.state.isForgotPasswordLayout && (
              <TextField
                type="password"
                hintText="Enter your Password"
                floatingLabelText="Password"
                onChange={(event, newValue) => this.setState({ password: newValue, errorMsg: '' })}
                onKeyDown={event => this.handleClick(event)}
              />
            )}
            <br />
            <br />
            <RaisedButton
              label={this.state.isForgotPasswordLayout ? 'Send a reset-password email' : 'Login'}
              disabled={!this.state.email || !(this.state.isForgotPasswordLayout || this.state.password)}
              primary={true}
              onClick={event => this.handleClick(event)}
            />
            <br />
            <span
              style={linkStyle}
              hidden={this.state.isForgotPasswordLayout}
              onClick={() => {
                this.setState({
                  isForgotPasswordLayout: true,
                });
              }}
            >
              Forgot password?
            </span>
            <br />
            <span
              style={linkStyle}
              onClick={() => {
                this.setState({ isForgotPasswordLayout: false });
              }}
              hidden={!this.state.isForgotPasswordLayout}
            >
              I remember the password!
            </span>
            <br />
            <div style={{ color: 'red' }}>{this.state.errorMsg}</div>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const linkStyle: React.CSSProperties = {
  cursor: 'pointer',
  color: 'blue',
  fontSize: 'x-small',
};

const styleLogoImg = {
  width: 200,
  height: 100,
};
