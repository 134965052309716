import React from 'react';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes from 'prop-types';

import Firebase from '../Firebase';
import '../App.css';
import Loader from '../loaderImg.gif';
import ServerErrorIcon from '../cross.png';
import { Table, IColumn } from '../Table';
import { CRUDOOL_SERVER_BASE_URL } from '../constants';
import Breadcrumb, { IBreadcrumb } from '../breadcrumb';
import utils from '../utils/utils';
import { useOrganizationContext } from '../Contexts/OrgContext';

enum SCREEN_STATUS {
  ALL_OK,
  ERROR,
  LOADING,
}

const propTypesOfComponent = {
  typeOfObject: PropTypes.number.isRequired,
  fromDateElementValue: PropTypes.string.isRequired,
  toDateElementValue: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
  params: PropTypes.shape({
    trackerId: PropTypes.string,
    tsId: PropTypes.string,
    trackerCampaignId: PropTypes.string,
    tsCampaignId: PropTypes.string,
    tsBannerId: PropTypes.string,
    tsTargetId: PropTypes.string,
    tsZoneId: PropTypes.string,
  }),
  shouldBeBlurred: PropTypes.bool,
};

type IProps = PropTypes.InferProps<typeof propTypesOfComponent>;

interface IServerResponse {
  table: {
    rows: unknown[];
    totals: unknown;
    columns: IColumn[];
  };
  fromDate: string;
  toDate: string;
  breadcrumbs: IBreadcrumb[];
}

async function loadData(
  orgId: string,
  params: IProps['params'],
  typeOfObject: number,
  fromDate: string,
  toDate: string
): Promise<IServerResponse> {
  if (!fromDate || !toDate) {
    throw new Error(`From & To date cannot be empty!`);
  }
  if (!params) {
    throw new Error(`Params cannot be empty!`);
  }
  const token = await Firebase.getUserToken();
  let apiURL = `${CRUDOOL_SERVER_BASE_URL}org/${orgId}/`;
  if (params.trackerId) {
    apiURL += `tracker/${params.trackerId}/`;
    if (params.tsId) {
      apiURL += `ts/${params.tsId}/`;

      if (params.trackerCampaignId) {
        apiURL += `trackerCampaign/${params.trackerCampaignId}/`;
        if (params.tsCampaignId) {
          apiURL += `tsCampaign/${params.tsCampaignId}/`;

          if (5 < typeOfObject) {
            switch (typeOfObject) {
              case 5.1:
                apiURL += `banner/`;
                break;
              case 5.2:
                apiURL += `target/`;
                break;
              case 5.3:
                apiURL += `zone/`;
                break;
              default:
                throw new Error(`BasePage.loadData - typeOfObject is unrecognized (${typeOfObject})`);
            }
          }
        }
      }
    }
  }
  const parsedFromDate = fromDate[fromDate.length - 1].toLowerCase() === 'z' ? fromDate : `${fromDate}Z`;
  const parsedToDate = toDate[toDate.length - 1].toLowerCase() === 'z' ? toDate : `${toDate}Z`;
  apiURL += `performance?fromDate=${parsedFromDate}&toDate=${parsedToDate}`;

  const response = await fetch(apiURL, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error(`Network response was not OK! response=${utils.convertToString(response)}`);
  }
  const responseJson = await response.json();
  if (responseJson.error) {
    throw new Error(`Dashboard.loadData - Error (responseJson.error): ${utils.convertToString(responseJson.error)}`);
  }
  if (!responseJson.success) {
    throw new Error(`Dashboard.loadData - Error (!responseJson.success): ${utils.convertToString(responseJson)}`);
  }
  return responseJson;
}

export function CrudoolStatsTable(props: IProps) {
  const [screenStatus, setScreenStatus] = React.useState(SCREEN_STATUS.LOADING);
  const [breadcrumbs, setBreadcrumbs] = React.useState<IBreadcrumb[]>([]);
  const [rows, setRows] = React.useState<Array<unknown>>([]);
  const [fromDateResponse, setFromDateResponse] = React.useState('');
  const [toDateResponse, setToDateResponse] = React.useState('');
  const [columns, setColumns] = React.useState<IColumn[]>([]);

  const { organization } = useOrganizationContext();

  utils.consoleLog('props3=' + utils.convertToString(props));

  React.useEffect(() => {
    if (organization) {
      setScreenStatus(SCREEN_STATUS.LOADING);
      loadData(organization.id, props.params, props.typeOfObject, props.fromDateElementValue, props.toDateElementValue)
        .then(responseJson => {
          const resultOutput = [...responseJson.table.rows, responseJson.table.totals];
          setColumns(responseJson.table.columns);
          setRows(resultOutput);
          setScreenStatus(SCREEN_STATUS.ALL_OK);
          setFromDateResponse(responseJson.fromDate);
          setToDateResponse(responseJson.toDate);
          setBreadcrumbs(responseJson.breadcrumbs);
        })
        .catch(error => {
          console.error(utils.convertToString(error));
          alert('Error retrieving data from server!');
          setScreenStatus(SCREEN_STATUS.ERROR);
        });
    }
  }, [organization, props]);

  let refreshFunction = undefined;
  if (organization) {
    refreshFunction = (event: unknown) => {
      setScreenStatus(SCREEN_STATUS.LOADING);
      loadData(organization.id, props.params, props.typeOfObject, props.fromDateElementValue, props.toDateElementValue)
        .then(responseJson => {
          const resultOutput = [...responseJson.table.rows, responseJson.table.totals];
          setColumns(responseJson.table.columns);
          setRows(resultOutput);
          setScreenStatus(SCREEN_STATUS.ALL_OK);
          setFromDateResponse(responseJson.fromDate);
          setToDateResponse(responseJson.toDate);
          setBreadcrumbs(responseJson.breadcrumbs);
        })
        .catch(error => {
          console.error(utils.convertToString(error));
          alert('Error retrieving data from server!');
          setScreenStatus(SCREEN_STATUS.ERROR);
        });
    };
  }

  switch (screenStatus) {
    case SCREEN_STATUS.ERROR:
    default:
      return <img src={ServerErrorIcon} style={styleServerErrorImg} alt="ServerError" />;

    case SCREEN_STATUS.LOADING:
      return <img src={Loader} style={styleLoaderImg} alt="loader" />;

    case SCREEN_STATUS.ALL_OK:
      utils.consoleLog('props1='+utils.convertToString(props));
      return (
        <div style={tblDiv}>
          <div>
            <Breadcrumb breadcrumbs={breadcrumbs} fromDateResponse={fromDateResponse} toDateResponse={toDateResponse} />
          </div>
          <div>
            Actual date range: {fromDateResponse} - {toDateResponse}
          </div>
          <Table columns={columns} rows={rows} shouldBeBlurred={props && props.shouldBeBlurred} />
        </div>
      );
  }
}
CrudoolStatsTable.propTypes = propTypesOfComponent;

const styleLoaderImg: React.CSSProperties = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const tblDiv: React.CSSProperties = {
  width: '95%',
  marginTop: '5%',
  marginLeft: '2.5%',
  zIndex: -999,
};

const styleServerErrorImg: React.CSSProperties = {
  width: '50%',
  height: 300,
  marginLeft: 300,
  marginTop: 20,
};
