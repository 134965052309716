import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import utils from './utils/utils';
import Login from './pages/Login';
import Firebase from './Firebase';
import * as AuthorizationRoles from './AuthorizationRoles';
import * as serviceWorker from './serviceWorker';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { OrganizationProvider } from './Contexts/OrgContext';
import Dashboard_deprecated from './pages/Crudool/Dashboard_deprecated';
import TSView from './pages/Crudool/tsView_Deprecated';
import Tracker from './pages/Tracker';
import VolCampaignNormalPage from './VolCampaignNormalPage';
import VolCampaignSpecialPage from './VolCampaignSpecialPage';
import TsCampaignSpecialPage from './TsCampaignSpecialPage';
import SiteDuplicator from './siteDuplicator';
import DBContent from './dBContent';
import LogRocket from 'logrocket';
import BinomCampaignConverter from './binomCampaignConverter';
import SAMI_Incidents from './pages/SAMI_Incidents';
import postbackGenerator from './pages/postbackGenerator';
import Settings from './pages/Settings';
import HotJar from './handlers/HotJar';
import { UserManagement } from './UserManagement';
import { Page1 } from './pages/Crudool/Page1';
import { Page2 } from './pages/Crudool/Page2';
import { Page3 } from './pages/Crudool/Page3';
import { Page4 } from './pages/Crudool/Page4';
import { Page5 } from './pages/Crudool/Page5';

// type IAllComponents = typeof Login | typeof Settings | typeof Tracker | typeof Dashboard_deprecated | typeof TSView | typeof VolCampaignNormalPage | typeof VolCampaignSpecialPage | typeof TsCampaignSpecialPage | typeof BinomCampaignConverter | typeof SAMI_Incidents | typeof postbackGenerator | typeof SiteDuplicator | typeof DBContent;
interface IRouteInput {
  // TODO: find a better type than `any`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentClass<any, any> | React.FunctionComponent<any>; // IAllComponents;
  visible: boolean;
  exact?: boolean;
  path: string;
}

HotJar.init();
if (process.env.REACT_APP_LOG_ROCKET) LogRocket.init(process.env.REACT_APP_LOG_ROCKET);
Firebase.onAuthStateChanged(checkIfLoggedInAndVerified.bind(this));
checkIfLoggedInAndVerified.bind(this);

let screenState: AuthorizationRoles.IScreenState = AuthorizationRoles.DEFAULT_SCREEN_STATE;
async function checkIfLoggedInAndVerified() {
  if (Firebase.isLoggedIn()) {
    const currentUser = Firebase.getCurrentUser();
    utils.debugLog('loggedInUser=' + JSON.stringify(currentUser, null, 2));
    if (currentUser.emailVerified) {
      const userEmail = currentUser.email || '';
      if (process.env.REACT_APP_LOG_ROCKET)
        LogRocket.identify(userEmail, {
          email: userEmail,
          roles: (await Firebase.getUserRoles()).join(', '),
        });

      screenState = await AuthorizationRoles.calcUserScreenState();

      ReactDOM.render(
        <HashRouter>
          <OrganizationProvider>
            <App />
          </OrganizationProvider>
        </HashRouter>,
        document.getElementById('root')
      );
    } else {
      ReactDOM.render(<Login isLoggedInButNotVerified={true} />, document.getElementById('root'));
    }
  } else {
    ReactDOM.render(
      <HashRouter>
        <OrganizationProvider>
          <App />
        </OrganizationProvider>
      </HashRouter>,
      document.getElementById('root')
    );
  }
}

function App() {
  return (
    <main>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/settings" component={Settings} />
        <PrivateRoute path="/tracker" component={Tracker} visible={screenState.pages.tracker} />
        <PrivateRoute exact path="/new" component={Page1} visible={screenState.pages.performanceDashboard} />
        <PrivateRoute exact path="/new/:trackerId" component={Page2} visible={screenState.pages.performanceDashboard} />
        <PrivateRoute
          exact
          path="/new/:trackerId/:tsId"
          component={Page3}
          visible={screenState.pages.performanceDashboard}
        />
        <PrivateRoute
          exact
          path="/new/:trackerId/:tsId/:trackerCampaignId"
          component={Page4}
          visible={screenState.pages.performanceDashboard}
        />
        <PrivateRoute
          exact
          path="/new/:trackerId/:tsId/:trackerCampaignId/:tsCampaignId"
          component={Page5}
          visible={screenState.pages.performanceDashboard}
        />
        <PrivateRoute
          exact
          path="/"
          component={Dashboard_deprecated}
          visible={screenState.pages.performanceDashboard}
        />
        <PrivateRoute exact path="/2/:tSId" component={TSView} visible={screenState.pages.performanceDashboard} />
        <PrivateRoute
          exact
          path="/3/:tSId/:id"
          component={VolCampaignNormalPage}
          visible={screenState.pages.performanceDashboard}
        />
        <PrivateRoute
          exact
          path="/3_special/:tSId/:id"
          component={VolCampaignSpecialPage}
          visible={screenState.pages.performanceDashboard}
        />
        <PrivateRoute
          exact
          path="/4_special2/:tSId/:tsCampId/:id"
          component={TsCampaignSpecialPage}
          visible={screenState.pages.performanceDashboard}
        />
        <PrivateRoute
          exact
          path="/binom-campaign-converter"
          component={BinomCampaignConverter}
          visible={screenState.pages.binomConverter}
        />
        <PrivateRoute
          exact
          path="/sami_incidents"
          component={SAMI_Incidents}
          visible={screenState.pages.SAMI_Incidents}
        />
        <PrivateRoute
          exact
          path="/postbackGenerator"
          component={postbackGenerator}
          visible={screenState.pages.postbackGenerator}
        />
        <PrivateRoute
          exact
          path="/siteDuplicator"
          component={SiteDuplicator}
          visible={screenState.pages.websiteDuplicator}
        />
        <PrivateRoute exact path="/dbContent" component={DBContent} visible={screenState.pages.dbContent} />
        <PrivateRoute
          exact
          path="/userManagement"
          component={UserManagement}
          visible={screenState.pages.userManagement}
        />
        <NotFoundRoute path="*" />
      </Switch>
    </main>
  );
}

function PrivateRoute({ component: Component, visible, ...rest }: IRouteInput): JSX.Element {
  try {
    utils.consoleLog(`Route=${utils.convertToString(Component.name)}`);
  } catch (err) {
    /* Do nothing */
  }
  return (
    <Route
      {...rest}
      render={props => {
        if (Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified) {
          if (visible) {
            // All good - continue...
            return <Component {...props} />;
          } else {
            // No permission - go to home screen
            utils.consoleLog(
              `PrivateRoute Error: User has no permissions to access '${
                Component.name
              }' component!\n${utils.convertToString(screenState)}`
            );
            utils.consoleError(`Error: User has no permissions to access this path.`);
            return <NotFoundRoute />;
          }
        } else {
          // Not logged in - go to login screen
          return (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }
      }}
    />
  );
}

function NotFoundRoute(args: { path?: string }): JSX.Element {
  utils.consoleLog('NotFoundRoute!');
  let pathToRedirectTo = '/';
  if (screenState.pages.performanceDashboard) {
    pathToRedirectTo = '/';
  } else if (screenState.pages.tracker) {
    pathToRedirectTo = '/tracker';
  } else {
    pathToRedirectTo = '/settings';
  }
  return <Redirect from="*" to={pathToRedirectTo} />;
}

serviceWorker.unregister();
