import Toolbar from 'material-ui/Toolbar';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Logo from '../logo_app.png';

import SIDEBAR from '../Sidebars';
import Firebase from '../Firebase';
// import utils from '../utils';
import '../App.css';

interface ICurrentProps {
  history?: string[];
}

interface ICurrentState {}

export default class Settings extends React.Component<ICurrentProps, ICurrentState> {
  handleLogout() {
    Firebase.logout();
  }

  render() {
    return (
      <div>
        <MuiThemeProvider>
          <>
            <div id="div">
              <SIDEBAR />
              <Toolbar style={styleToolbar}>
                <img src={Logo} style={styleLogoImg} alt="logo" />
                <h3>Settings</h3>
                <ReactTooltip />

                <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => this.handleLogout()} />
              </Toolbar>
            </div>
            <div style={{ textAlign: 'center' }}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <span>
                Hello{' '}
                {Firebase.getCurrentUser()
                  ? Firebase.getCurrentUser().displayName || Firebase.getCurrentUser().email
                  : ''}
                !
              </span>
              <br />
              <a href="/" onClick={() => Firebase.logout()}>
                Logout
              </a>
            </div>
          </>
        </MuiThemeProvider>
      </div>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh: React.CSSProperties = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};
const styleToolbar = {
  marginTop: -30,
};

const styleLoaderImg = {
  width: 70,
  height: 70,
  // marginLeft: 550
};
