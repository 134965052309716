import React from 'react';
import Firebase from './Firebase';
import { Table, IColumn } from './Table';
import Loader from './loaderImg.gif';
import { CRUDOOL_SERVER_BASE_URL_DEPRECATED } from './constants';
import ServerErrorIcon from './cross.png';

interface ICurrentProps {
  tSId: string;
  tsCampId: string;
  id: string;
}

interface ICurrentState {
  loaderVisibility: boolean;
  tableVisibility: boolean;
  volCampaignHeadLink: string;
  volCampaignHeadText: string;
  columns: IColumn[];
  rows: unknown[];
  serverError: boolean;
}

export default class TsCampaign_Devices extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      columns: [],
      rows: [],
      loaderVisibility: true,
      tableVisibility: false,
      volCampaignHeadLink: '',
      volCampaignHeadText: '',
      serverError: false,
    };
  }

  loadTSData(fromDate: string, toDate: string, item1: string, item2: string, item3: string) {
    this.setState({
      loaderVisibility: true,
      tableVisibility: false,
    });
    if (fromDate === '' || toDate === '') {
      alert('From & To date cannot be empty!');
      return;
    }
    return Firebase.getUserToken().then(token => {
      return fetch(
        CRUDOOL_SERVER_BASE_URL_DEPRECATED +
          'dashboard/performance/' +
          item1 +
          '/' +
          item2 +
          '/' +
          item3 +
          '/devices?fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.error) throw responseJson.error;

          const lastItem = responseJson.breadcrumbs.length - 1;
          this.setState({
            volCampaignHeadText: responseJson.breadcrumbs[lastItem].display,
            volCampaignHeadLink: responseJson.breadcrumbs[lastItem].link,
          });

          const resultOutput = [];
          for (let i = 0; i < responseJson.table.rows.length; i++) {
            resultOutput.push(responseJson.table.rows[i]);
          }
          resultOutput.push(responseJson.table.totals);

          this.setState({
            columns: responseJson.table.columns,
            rows: resultOutput,
            loaderVisibility: false,
            tableVisibility: true,
            serverError: false,
          });
        })
        .catch(error => {
          console.error(error);
          alert('Error retrieving data from server!');
          this.setState({
            serverError: true,
            loaderVisibility: false,
          });
        });
    });
  }

  componentDidMount() {
    console.log(this.props);
    const today = new Date();
    const tmpThisMonth = today.getMonth() + 1;
    const thisMonth = tmpThisMonth < 10 ? '0' + tmpThisMonth : tmpThisMonth;
    const tmpThisDate = today.getDate();
    const thisDate = tmpThisDate < 10 ? '0' + tmpThisDate : tmpThisDate;

    const fromDate = today.getFullYear() + '-' + thisMonth + '-01T00:00';
    const toDate = today.getFullYear() + '-' + thisMonth + '-' + thisDate + 'T23:59';

    this.loadTSData(fromDate, toDate, this.props.tSId, this.props.tsCampId, this.props.id);
  }

  render() {
    return (
      <div>
        {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
        {this.state.tableVisibility ? (
          <div>
            Devices of{' '}
            <a href={this.state.volCampaignHeadLink} rel="noopener noreferrer" target="_blank">
              {this.state.volCampaignHeadText}
            </a>
            <Table columns={this.state.columns} rows={this.state.rows} />
          </div>
        ) : null}
        {this.state.serverError ? <img src={ServerErrorIcon} style={styleServerErrorImg} alt="ServerError" /> : null}
      </div>
    );
  }
}

const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const styleServerErrorImg = {
  width: '50%',
  height: 300,
  marginLeft: 300,
  marginTop: 20,
};
