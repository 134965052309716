// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app';
import utils from './utils/utils';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore'; // cSpell:disable-line
import 'firebase/analytics';

const FIREBASE_CONFIG = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '{}');
if (!Object.keys(FIREBASE_CONFIG).length) {
  throw new Error(
    `Error: 'process.env.REACT_APP_FIREBASE_CONFIG' wasn't configured right! (${process.env.REACT_APP_FIREBASE_CONFIG})`
  );
}

// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);
firebase.analytics();
firebase.auth().useDeviceLanguage();

function logEvent(strEvent: string): void {
  firebase.analytics().logEvent(strEvent);
  // Example: firebase.analytics().logEvent('notification_received');
}

function register(email: string, password: string): Promise<firebase.auth.UserCredential> {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(registeredUser => {
      return registeredUser;
    })
    .catch(function (error) {
      // Handle Errors here.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
      // Error Examples:
      // {"code":"auth/invalid-email","message":"The email address is badly formatted."}
      // {"code":"auth/email-already-in-use","message":"The email address is already in use by another account."}
    });
}

function login(email: string, password: string): Promise<firebase.auth.UserCredential> {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(registeredUser => {
      return registeredUser;
    })
    .catch(function (error) {
      // Handle Errors here.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function logout(): Promise<boolean> {
  return firebase
    .auth()
    .signOut()
    .then(function () {
      // Sign-out successful.
      return true;
    })
    .catch(function (error) {
      // An error happened.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function getUserToken(): Promise<string> {
  return getCurrentUser()
    .getIdToken(true)
    .then(idToken => {
      return idToken;
    })
    .catch(error => {
      // Handle Errors here.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

export function refreshUserClaims(): Promise<string> {
  return getUserToken();
}

function getUserRoles(): Promise<string[]> {
  const currentLoggedUser = getCurrentUser();
  if (currentLoggedUser) {
    return currentLoggedUser
      .getIdTokenResult()
      .then(idTokenResult => {
        if (
          idTokenResult &&
          idTokenResult.claims &&
          idTokenResult.claims.roles &&
          Array.isArray(idTokenResult.claims.roles)
        ) {
          utils.debugLog(`idTokenResult.claims.roles=${utils.convertToString(idTokenResult.claims.roles)}`);
          return idTokenResult.claims.roles;
          // if(idTokenResult.claims.admin) {
          //   roles.push('admin');
          // }
          // if(idTokenResult.claims.developer) {
          //   roles.push('developer');
          // }
          // // roles=Object.keys(idTokenResult.claims).filter(role=>!!idTokenResult.claims[role]);
        } else {
          return [];
        }
      })
      .catch(error => {
        // Handle Errors here.
        utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
        throw error;
      });
  } else {
    utils.consoleError('FB - No user is logged in!');
    return Promise.resolve([]);
  }
}

function isUserAdmin(): Promise<boolean> {
  return getUserRoles().then(roles => roles.includes('admin'));
}

function isLoggedIn(): boolean {
  return !!firebase.auth().currentUser;
}

function getCurrentUser(): firebase.User {
  return firebase.auth().currentUser!;
}

function updateDisplayName(displayName: string): Promise<boolean> {
  const user = getCurrentUser();

  return user
    .updateProfile({
      displayName: displayName,
    })
    .then(function () {
      // Update successful.
      return true;
    })
    .catch(function (error) {
      // An error happened.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function updateUserPhoto(photoURL: string): Promise<boolean> {
  const user = getCurrentUser();

  return user
    .updateProfile({
      photoURL: photoURL, // Example: "https://example.com/jane-q-user/profile.jpg"
    })
    .then(function () {
      // Update successful.
      return true;
    })
    .catch(function (error) {
      // An error happened.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function onAuthStateChanged(callback: () => unknown): firebase.Unsubscribe {
  return firebase.auth().onAuthStateChanged(callback);
}

function changePassword(newPassword: string): Promise<boolean> {
  // Important: To set a user's password, the user must have signed in recently. See Re-authenticate a user.
  const user = getCurrentUser();

  return user
    .updatePassword(newPassword)
    .then(function () {
      // Update successful.
      return true;
    })
    .catch(error => {
      // Handle Errors here.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function sendResetPasswordEmail(email: string): Promise<boolean> {
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(function () {
      // Email sent.
      return true;
    })
    .catch(function (error) {
      // An error happened.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

function resendEmailVerification(): Promise<boolean> {
  return getCurrentUser()
    .sendEmailVerification()
    .then(function () {
      // Email sent.
      return true;
    })
    .catch(function (error) {
      // An error happened.
      utils.consoleError('GFB Error = ' + JSON.stringify(error, null, 2));
      throw error;
    });
}

export enum ORG_ROLES {
  OWNER = 'owner',
  MEMBER = 'member',
}
export function getOrgRolesOfUser(): Promise<
  {
    orgId: string;
    role: ORG_ROLES;
  }[]
> {
  const currentLoggedUser = getCurrentUser();
  if (currentLoggedUser) {
    return currentLoggedUser.getIdTokenResult().then(idTokenResult => {
      if (
        idTokenResult &&
        idTokenResult.claims &&
        idTokenResult.claims.orgRoles &&
        Array.isArray(idTokenResult.claims.orgRoles)
      ) {
        return idTokenResult.claims.orgRoles;
      } else {
        return [];
      }
    });
  } else {
    return Promise.resolve([]);
  }
}

const exportObj = {
  logEvent,
  register,
  login,
  logout,
  getUserToken,
  getUserRoles,
  isUserAdmin,
  isLoggedIn,
  getCurrentUser,
  updateDisplayName,
  updateUserPhoto,
  onAuthStateChanged,
  changePassword,
  sendResetPasswordEmail,
  resendEmailVerification,
};
export default exportObj;
