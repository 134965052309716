import React from 'react';
import { Table, IColumn, ITable } from './Table';

export interface ITarget {
  childrenDailyStats: ITable<unknown>;
  name: {
    link: string;
    display: string;
  };
}

interface ICurrentProps {
  tsCampaignDataDailyStat: ITable<unknown>;
  tsTarget: ITarget[];
}

interface ICurrentState {
  columns: IColumn[];
  dailyStatRowsOutput: unknown[];
}

export default class TsTargetDailyStatsTable extends React.Component<ICurrentProps, ICurrentState> {
  componentDidMount() {
    console.log(this.props.tsCampaignDataDailyStat);
    if (this.props.tsCampaignDataDailyStat) {
      const dailyStatRows = [];
      for (let i = 0; i < this.props.tsCampaignDataDailyStat.rows.length; i++) {
        dailyStatRows.push(this.props.tsCampaignDataDailyStat.rows[i]);
      }
      dailyStatRows.push(this.props.tsCampaignDataDailyStat.totals);

      this.setState({
        columns: this.props.tsCampaignDataDailyStat.columns,
        dailyStatRowsOutput: dailyStatRows,
      });
    }
  }

  render() {
    return (
      <div style={styleDiv}>
        <div style={stylePad}>
          <p>
            Daily Stats of <a href={this.props.tsTarget[0].name.link}>{this.props.tsTarget[0].name.display}</a>
          </p>
          {this.props &&
          this.props.tsCampaignDataDailyStat &&
          this.state &&
          this.state.columns &&
          this.state.dailyStatRowsOutput ? (
            <Table columns={this.state.columns} rows={this.state.dailyStatRowsOutput} />
          ) : null}
        </div>
      </div>
    );
  }
}

const styleDiv = {
  backgroundColor: '#d5e6f7',
  marginLeft: 20,
  marginTop: 20,
};

const stylePad = {
  padding: 20,
};
