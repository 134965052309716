import * as React from 'react';
import Firebase from '../../Firebase';
import Loader from '../../loaderImg.gif';
import { Table } from '../../Table';
import utils from '../../utils/utils';
import { TRACKER_SERVER_BASE_URL } from '../../constants';
import {
  convertDateStringToUTCDate,
  addDaysToDate,
  startOfToday,
  endOfToday,
  startOfThisMonth,
  endOfThisMonth,
  startOfLastMonth,
  endOfLastMonth,
  endOfThisYear,
  startOfThisYear,
  startOfLastNDays,
  endOfLastNDays,
} from '../../utils/dateUtils';

interface IStatistics {
  confirm: string;
  loaderVisibility: boolean;
  affiliateTableColumns: any;
  advertiserTableColumns: any;
  affiliateTableResults: any;
  advertiserTableResults: any;
  tableOneVisibility: boolean;
  tableTwoVisibility: boolean;
  tableToShow: string;
  showCustomDatePicker: boolean;
}
enum TIME_FRAME {
  TODAY = 't',
  YESTERDAY = 'y',
  LAST_SEVEN_DAYS = 'ls',
  THIS_MONTH = 'tm',
  LAST_MONTH = 'lm',
  THIS_YEAR = 'ty',
  CUSTOM = 'c',
}

let startDate = '';
let endDate = '';
class Statistics extends React.Component<any, IStatistics> {
  constructor(props: IStatistics) {
    super(props);
    this.state = {
      confirm: '',
      affiliateTableColumns: [],
      advertiserTableColumns: [],
      affiliateTableResults: [],
      advertiserTableResults: [],
      loaderVisibility: true,
      tableOneVisibility: false,
      tableTwoVisibility: false,
      tableToShow: 'Table1',
      showCustomDatePicker: false,
    };
    this.handleStatTable = this.handleStatTable.bind(this);
  }

  COLUMNS_AFFILIATE = [
    {
      label: 'Offer Name',
      width: '100',
      type: 'string',
      isTableKey: true,
      key: 'offer',
      filterable: true,
    },
    {
      label: 'Total Leads Accepted',
      type: 'string',
      filterable: true,
      key: 'count',
    },
    {
      label: 'Revenue',
      type: 'string',
      filterable: true,
      key: 'payout',
    },
  ];

  COLUMNS_ADVERTISER = [
    {
      label: 'ID',
      width: '120',
      type: 'string',
      isTableKey: true,
      key: '_id',
      filterable: true,
    },
    {
      label: 'Lead Details',
      type: 'string',
      filterable: true,
      key: 'email',
    },
    {
      label: 'Accepted By',
      type: 'string',
      filterable: true,
      key: 'offer',
    },
    {
      label: 'Revenue',
      type: 'string',
      filterable: true,
      key: 'payout',
    },
  ];

  componentDidMount() {
    const startDate = startOfToday();
    const endDate = endOfToday();
    this.acceptedLeadsByAdvertiserData(startDate, endDate);
    this.acceptedLeadDetailsByAdvertiser(startDate, endDate);
  }

  async acceptedLeadsByAdvertiserData(startDate: Date, endDate: Date) {
    const token = await Firebase.getUserToken();
    try {
      const response = await fetch(
        TRACKER_SERVER_BASE_URL + `leadsAcceptedByAdvertiserDetails?start=${startDate}&end=${endDate}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const responseJson = await response.json();
      if (responseJson.error) throw responseJson.error;

      this.setState({
        affiliateTableColumns: this.COLUMNS_AFFILIATE,
        affiliateTableResults: responseJson,
        loaderVisibility: false,
        tableOneVisibility: true,
      });
    } catch (error) {
      utils.consoleError(`readableDate - Error: ${utils.convertToString(error)}`);
      alert('Error retrieving data from server!');
    }
  }

  async acceptedLeadDetailsByAdvertiser(startDate: Date, endDate: Date) {
    const token = await Firebase.getUserToken();
    try {
      const response = await fetch(
        TRACKER_SERVER_BASE_URL + `acceptedLeadsDetailsPerAdvertiser?start=${startDate}&end=${endDate}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const responseJson = await response.json();
      if (responseJson.error) throw responseJson.error;

      this.setState({
        advertiserTableColumns: this.COLUMNS_ADVERTISER,
        advertiserTableResults: responseJson,
        loaderVisibility: false,
        tableTwoVisibility: true,
      });
    } catch (error) {
      utils.consoleError(`readableDate - Error: ${utils.convertToString(error)}`);
      alert('Error retrieving data from server!');
    }
  }

  handleChangeTimeFrame(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedValue = event.target.value;
    this.setState({
      showCustomDatePicker: selectedValue === TIME_FRAME.CUSTOM,
      loaderVisibility: selectedValue !== TIME_FRAME.CUSTOM,
      tableOneVisibility: selectedValue === TIME_FRAME.CUSTOM,
      tableTwoVisibility: selectedValue === TIME_FRAME.CUSTOM,
    });
    switch (selectedValue) {
      case TIME_FRAME.TODAY: {
        this.acceptedLeadsByAdvertiserData(startOfToday(), endOfToday());
        this.acceptedLeadDetailsByAdvertiser(startOfToday(), endOfToday());
        break;
      }
      case TIME_FRAME.YESTERDAY:
        this.acceptedLeadsByAdvertiserData(startOfLastNDays(1), endOfLastNDays(1));
        this.acceptedLeadDetailsByAdvertiser(startOfLastNDays(1), endOfLastNDays(1));
        break;
      case TIME_FRAME.LAST_SEVEN_DAYS: {
        this.acceptedLeadsByAdvertiserData(startOfLastNDays(7), endOfLastNDays(1));
        this.acceptedLeadDetailsByAdvertiser(startOfLastNDays(7), endOfLastNDays(1));
        break;
      }
      case TIME_FRAME.THIS_MONTH:
        this.acceptedLeadsByAdvertiserData(startOfThisMonth(), endOfThisMonth());
        this.acceptedLeadDetailsByAdvertiser(startOfThisMonth(), endOfThisMonth());
        break;
      case TIME_FRAME.LAST_MONTH:
        this.acceptedLeadsByAdvertiserData(startOfLastMonth(), endOfLastMonth());
        this.acceptedLeadDetailsByAdvertiser(startOfLastMonth(), endOfLastMonth());
        break;
      case TIME_FRAME.THIS_YEAR:
        this.acceptedLeadsByAdvertiserData(startOfThisYear(), endOfThisYear());
        this.acceptedLeadDetailsByAdvertiser(startOfThisYear(), endOfThisYear());
        break;
      case TIME_FRAME.CUSTOM:
        break;
      default:
        console.error(`No match. Selected Value = ${selectedValue}`);
        break;
    }
  }

  handleStatTable(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value === 'Table2') {
      this.setState({
        tableToShow: 'Table2',
      });
    } else {
      this.setState({
        tableToShow: 'Table1',
      });
    }
  }

  handleChangeCustomDate(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'startDate') {
      startDate = event.target.value;
    } else if (event.target.name === 'endDate') {
      endDate = event.target.value;
    }
    if (startDate && endDate) {
      this.setState({
        tableOneVisibility: false,
        tableTwoVisibility: false,
        loaderVisibility: true,
      });
      this.acceptedLeadsByAdvertiserData(
        convertDateStringToUTCDate(startDate),
        addDaysToDate(convertDateStringToUTCDate(endDate), 1)
      );
      this.acceptedLeadDetailsByAdvertiser(
        convertDateStringToUTCDate(startDate),
        addDaysToDate(convertDateStringToUTCDate(endDate), 1)
      );
    }
  }

  render() {
    return (
      <div>
        <br></br>
        <div className="row">
          <div className="col-6">
            <div style={radioDiv}>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="radio"
                      name="site_name"
                      value={'Table1'}
                      checked={this.state.tableToShow === 'Table1'}
                      onChange={this.handleStatTable}
                    />
                    <label style={radioLabel}>Total Accepted Leads Per Advertiser</label>
                  </td>
                  <td>
                    <input
                      type="radio"
                      name="address"
                      value={'Table2'}
                      checked={this.state.tableToShow === 'Table2'}
                      onChange={this.handleStatTable}
                    />
                    <label style={radioLabel}>Accepted Leads</label>
                  </td>
                </tr>
              </tbody>
            </div>
          </div>
          <div className="col-2">
            <div>
              <select onChange={this.handleChangeTimeFrame.bind(this)} defaultValue={TIME_FRAME.TODAY}>
                <option value={TIME_FRAME.TODAY}>Today</option>
                <option value={TIME_FRAME.YESTERDAY}>Yesterday</option>
                <option value={TIME_FRAME.LAST_SEVEN_DAYS}>Last 7 days</option>
                <option value={TIME_FRAME.THIS_MONTH}>This Month</option>
                <option value={TIME_FRAME.LAST_MONTH}>Last Month</option>
                <option value={TIME_FRAME.THIS_YEAR}>This Year</option>
                <option value={TIME_FRAME.CUSTOM}>Custom</option>
              </select>
            </div>
          </div>
          <div className="col-4">
            {this.state.showCustomDatePicker && (
              <div className="row">
                <div className="col-6">
                  <label>From: </label>
                  <input type="date" onChange={this.handleChangeCustomDate.bind(this)} name="startDate" />
                </div>
                <div className="col-6">
                  <label>To: </label>
                  <input type="date" onChange={this.handleChangeCustomDate.bind(this)} name="endDate" />
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
        <div className="row">
          <div className="col-12">
            {this.state.tableOneVisibility && this.state.tableToShow == 'Table1' ? (
              <div style={tblDiv}>
                <Table
                  columns={this.state.affiliateTableColumns}
                  rows={this.state.affiliateTableResults}
                  hasTotalRow={false}
                />
              </div>
            ) : null}
          </div>
          <div className="col-12">
            {this.state.tableTwoVisibility && this.state.tableToShow == 'Table2' ? (
              <div style={tblDiv}>
                <Table
                  columns={this.state.advertiserTableColumns}
                  rows={this.state.advertiserTableResults}
                  hasTotalRow={false}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}
const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const tblDiv = {
  width: '95%',
  marginTop: '2%',
  marginLeft: '2.5%',
};

const radioDiv = {
  marginLeft: '2.5%',
};

const radioLabel = {
  marginLeft: 5,
  fontSize: 14,
  marginRight: 10,
};

export default Statistics;
