import Firebase from './Firebase';
import utils from './utils/utils';

export interface IScreenState {
  debounceCredit: boolean;
  pages: {
    performanceDashboard: boolean;
    tracker: boolean;
    websiteDuplicator: boolean;
    dbContent: boolean;
    dailyReport: boolean;
    binomConverter: boolean;
    SAMI_Incidents: boolean;
    postbackGenerator: boolean;
    userManagement: boolean;
  };
}

export const DEFAULT_SCREEN_STATE: IScreenState = {
  debounceCredit: false,
  pages: {
    performanceDashboard: false,
    tracker: false,
    websiteDuplicator: false,
    dbContent: false,
    dailyReport: false,
    binomConverter: false,
    SAMI_Incidents: false,
    postbackGenerator: false,
    userManagement: false,
  },
};

export enum ROLES {
  ADMIN = 'ADMIN',
  DEVELOPER = 'DEVELOPER',
  TRACKER_ADMIN = 'TRACKER_ADMIN',
  CRUDOOL_ADMIN = 'CRUDOOL_ADMIN',
  CRUDOOL_USER = 'CRUDOOL_USER',
  POSTBACK_GENERATOR = 'POSTBACK_GENERATOR',
  EVERYONE = 'EVERYONE',
}

export function isUserAdminOrHasAnyOfTheseRoles(userRoles: ROLES[], requiredRoles: ROLES | ROLES[]): boolean {
  let _requiredRoles = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles];
  if (_requiredRoles.includes(ROLES.EVERYONE)) {
    return true;
  } else {
    _requiredRoles = [..._requiredRoles, ROLES.ADMIN, ROLES.DEVELOPER];
    return Boolean(utils.intersectionOfArrays(_requiredRoles, userRoles).length);
  }
}

export async function calcUserScreenState(): Promise<IScreenState> {
  const userRoles: ROLES[] = (await Firebase.getUserRoles()) as ROLES[];
  const screenState: IScreenState = {
    debounceCredit: isUserAdminOrHasAnyOfTheseRoles(userRoles, []),
    pages: {
      tracker: isUserAdminOrHasAnyOfTheseRoles(userRoles, [ROLES.TRACKER_ADMIN]),
      performanceDashboard: isUserAdminOrHasAnyOfTheseRoles(userRoles, [ROLES.CRUDOOL_ADMIN, ROLES.CRUDOOL_USER]),
      websiteDuplicator: isUserAdminOrHasAnyOfTheseRoles(userRoles, []),
      dbContent: isUserAdminOrHasAnyOfTheseRoles(userRoles, []),
      dailyReport: isUserAdminOrHasAnyOfTheseRoles(userRoles, []),
      binomConverter: isUserAdminOrHasAnyOfTheseRoles(userRoles, []),
      SAMI_Incidents: isUserAdminOrHasAnyOfTheseRoles(userRoles, []),
      postbackGenerator: isUserAdminOrHasAnyOfTheseRoles(userRoles, [ROLES.POSTBACK_GENERATOR]),
      userManagement: isUserAdminOrHasAnyOfTheseRoles(userRoles, [ROLES.ADMIN]),
    },
  };

  return screenState;
}
