import React from 'react';
import './App.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosArrowRoundForward } from 'react-icons/io';
import * as utils from './utils/utils';
import * as dateUtils from './utils/dateUtils';

export interface IBreadcrumb {
  link: string;
  display: string;
}

interface ICurrentProps {
  breadcrumbs: IBreadcrumb[];
  fromDateResponse: string;
  toDateResponse: string;
}

interface ICurrentState {}

export default class Breadcrumb extends React.Component<ICurrentProps, ICurrentState> {
  render() {
    if (!this.props.breadcrumbs) {
      return null;
    }
    const { fromDateResponse, toDateResponse } = this.props;
    const timeRangeElement =
      utils.isValidDate(fromDateResponse) && utils.isValidDate(toDateResponse) ? (
        <div style={styleTimeFrame}>
          `(Time-range: from ${utils.verifyLeadingZeros(fromDateResponse)} until $
          {dateUtils.convertEndTimestampToEndDate(new Date(toDateResponse))})`
        </div>
      ) : null;

    return (
      <div>
        <div style={styleBreadcrumb}>
          {this.props.breadcrumbs.map((value, index) => {
            return (
              <div key={index}>
                <a href={value.link}>{value.display}</a>
                {this.props.breadcrumbs.length - 1 === index ? null : (
                  <span>
                    &nbsp;&nbsp;&nbsp;
                    <IoIosArrowRoundForward />
                    &nbsp;&nbsp;&nbsp;
                  </span>
                )}
              </div>
            );
          })}
        </div>
        {timeRangeElement}
      </div>
    );
  }
}

const styleBreadcrumb: React.CSSProperties = {
  marginTop: -40,
  marginBottom: 25,
  display: 'flex',
  flexDirection: 'row',
  fontSize: 12,
};

const styleTimeFrame: React.CSSProperties = {
  marginTop: -15,
  marginBottom: 15,
  fontSize: 12,
};
