import React from 'react';
import { Table, ITable } from './Table';
import TsTargetDailyStats, { ITarget } from './TsTargetDailyStatsTable';

interface ICurrentProps {
  tsTargetFull: ITable<ITarget>;
  tsTargetDailyStat: ITable<unknown>;
  tsTargetData: {
    name: {
      link: string;
      display: string;
    };
  };
  index: number;
}

interface ICurrentState {}

export default class TsTarget extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      tsCampaignData: [],
      tsCampaignLink: '',
      tsCampaignDisplay: '',
      tsCampaignDataTableRows: [],
      tsCampaignDataTableColumns: [],
    };
  }

  render() {
    const rows: ITarget[] = [];
    rows.push(this.props.tsTargetFull.rows[this.props.index]);
    // delete rows[0].childrenDailyStats;

    return (
      <div style={styleDiv}>
        <div style={stylePad}>
          <a href={this.props.tsTargetData.name.link}>{this.props.tsTargetData.name.display}</a>
          <div>
            <Table columns={this.props.tsTargetFull.columns} rows={rows} />
          </div>
          <TsTargetDailyStats tsCampaignDataDailyStat={this.props.tsTargetDailyStat} tsTarget={rows} />
        </div>
      </div>
    );
  }
}

const styleDiv = {
  backgroundColor: '#e4eaf0',
  marginLeft: 20,
  marginTop: 20,
};

const stylePad = {
  padding: 20,
};
