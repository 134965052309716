import React from 'react';
import Firebase from '../../Firebase';
import '../../App.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import Toolbar from 'material-ui/Toolbar';
import Logo from '../../logo_app.png';
import { FaUndo } from 'react-icons/fa';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, IColumn } from '../../Table';
import Loader from '../../loaderImg.gif';
import { CRUDOOL_SERVER_BASE_URL_DEPRECATED } from '../../constants';
import SIDEBAR from '../../Sidebars';
import DebounceCredit from '../../debounceCredit';
import Breadcrumb, { IBreadcrumb } from '../../breadcrumb';

interface ICurrentProps {
  match: {
    params: {
      tSId: string;
    };
  };
}

interface ICurrentState {
  tableVisibility: boolean;
  loaderVisibility: boolean;
  time: number;
  breadcrumbs: IBreadcrumb[];
  fromDateResponse: string;
  toDateResponse: string;
  columns: IColumn[];
  rows: unknown[];
}

export default class TSView extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      columns: [],
      rows: [],
      breadcrumbs: [],
      fromDateResponse: '',
      toDateResponse: '',
      loaderVisibility: true,
      tableVisibility: false,
      time: Date.now(),
    };
  }

  handleLogout() {
    Firebase.logout();
  }

  handleRefresh() {
    const fromDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-from'
    ) as HTMLInputElement | null;
    const toDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-to'
    ) as HTMLInputElement | null;
    const fromDate: string = (fromDateElement && fromDateElement.value) || '';
    const toDate: string = (toDateElement && toDateElement.value) || '';
    this.loadTSViewData(fromDate, toDate, this.props.match.params.tSId);
    this.setState({
      time: Date.now(),
    });
  }

  loadTSViewData(fromDate: string, toDate: string, tsItem: string) {
    this.setState({
      loaderVisibility: true,
      tableVisibility: false,
    });

    return Firebase.getUserToken().then(token => {
      return fetch(
        `${CRUDOOL_SERVER_BASE_URL_DEPRECATED}dashboard/performance/${tsItem}/?fromDate=${fromDate}&toDate=${toDate}`,
        {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.error) throw responseJson.error;
          const i = 0,
            resultOutput = [];
          const breadcrumbOutput = [];
          for (let x = 0; x < responseJson.breadcrumbs.length; x++) {
            breadcrumbOutput.push(responseJson.breadcrumbs[x]);
          }
          for (let i = 0; i < responseJson.table.rows.length; i++) {
            resultOutput.push(responseJson.table.rows[i]);
          }

          resultOutput.push(responseJson.table.totals);

          this.setState({
            columns: responseJson.table.columns,
            rows: resultOutput,
            loaderVisibility: false,
            tableVisibility: true,
            fromDateResponse: responseJson.fromDate,
            toDateResponse: responseJson.toDate,
            breadcrumbs: breadcrumbOutput,
          });
        })
        .catch(error => {
          console.error(error);
          alert('Error retrieving data from server!');
        });
    });
  }

  componentDidMount() {
    console.log(this.props.match.params.tSId);
    const today = new Date();
    const tmpThisMonth = today.getMonth() + 1;
    const thisMonth = tmpThisMonth < 10 ? '0' + tmpThisMonth : tmpThisMonth;
    const tmpThisDate = today.getDate();
    const thisDate = tmpThisDate < 10 ? '0' + tmpThisDate : tmpThisDate;

    const fromDate = today.getFullYear() + '-' + thisMonth + '-01T00:00';
    const toDate = today.getFullYear() + '-' + thisMonth + '-' + thisDate + 'T23:59';

    const fromDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-from'
    ) as HTMLInputElement | null;
    const toDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-to'
    ) as HTMLInputElement | null;
    if (fromDateElement) fromDateElement.value = fromDate;
    if (toDateElement) toDateElement.value = toDate;

    //var tsItem = window.location.href.split("#2/").pop();
    this.loadTSViewData(fromDate, toDate, this.props.match.params.tSId);
  }

  render() {
    return (
      <MuiThemeProvider>
        <div id="div">
          <SIDEBAR />
          <Toolbar style={styleToolbar}>
            <img src={Logo} style={styleLogoImg} alt="logo" />
            <h3>Traffic Sources - View</h3>
            <FaUndo data-tip="Refresh" style={styleRefresh} onClick={event => this.handleRefresh()} />
            <ReactTooltip />
            <label style={styleText}>From:</label>
            <TextField style={styleDateText} id="datetime-local-from" type="datetime-local" />
            <label style={styleText}>To:</label>
            <TextField style={styleDateText} id="datetime-local-to" type="datetime-local" />
            <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => this.handleLogout()} />
          </Toolbar>
          {this.state.tableVisibility ? <DebounceCredit time={this.state.time} /> : null}
          {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
          {this.state.tableVisibility ? (
            <div style={tblDiv}>
              <div>
                <Breadcrumb
                  breadcrumbs={this.state.breadcrumbs}
                  fromDateResponse={this.state.fromDateResponse}
                  toDateResponse={this.state.toDateResponse}
                />
              </div>
              <Table columns={this.state.columns} rows={this.state.rows} />
            </div>
          ) : null}
        </div>
      </MuiThemeProvider>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
};
const styleDateText = {
  marginTop: 5,
};

const styleText = {
  marginTop: 20,
};

const tblDiv = {
  width: '95%',
  marginTop: '5%',
  marginLeft: '2.5%',
};

const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const styleToolbar = {
  marginTop: -30,
};
