// cSpell:ignore hjid hjsv
import { hotjar } from 'react-hotjar';

import utils from 'src/utils/utils';
export default class HotJar {
  static init() {
    const _HotJar = {
      hjid: parseFloat(process.env.REACT_APP_HOT_JAR_ID || ''),
      hjsv: parseFloat(process.env.REACT_APP_HOT_JAR_SV || ''),
    };
    if (_HotJar.hjid && _HotJar.hjsv) {
      utils.debugLog(`HotJar=${utils.convertToString(_HotJar)}`);
      hotjar.initialize(_HotJar.hjid, _HotJar.hjsv);
    }
  }
}
