import React from 'react';
import Firebase from './Firebase';
import * as AuthorizationRoles from './AuthorizationRoles';
import './App.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CRUDOOL_SERVER_BASE_URL_DEPRECATED } from './constants';
import utils from './utils/utils';

interface ICurrentProps {
  time: number;
}

interface ICurrentState {
  debounceCreditValue: boolean;
  textVisibility: boolean;
  creditOutput: string;
  newTime: number;
  prevTime: number;
}

export default class DebounceCredit extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      debounceCreditValue: false,
      textVisibility: false,
      creditOutput: '',
      prevTime: 1,
      newTime: 1,
    };
  }

  loadDebounceCredit() {
    this.setState({
      textVisibility: false,
    });

    return Firebase.getUserToken().then(token => {
      return fetch(CRUDOOL_SERVER_BASE_URL_DEPRECATED + 'dashboard/debounceCredit', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.error) {
            utils.consoleError('Debounce credit - Error: ' + responseJson.error);
          }
          const credit: string = isNaN(responseJson.credit)
            ? responseJson.credit
            : parseInt(responseJson.credit).toLocaleString();
          this.setState({
            creditOutput: credit,
            textVisibility: true,
          });
        })
        .catch(error => {
          utils.consoleError('Debounce credit - Error:: ' + error);
        });
    });
  }

  componentDidMount() {
    AuthorizationRoles.calcUserScreenState().then(screenState => {
      this.setState({
        debounceCreditValue: screenState.debounceCredit,
      });
      if (this.state.debounceCreditValue && this.state.newTime >= this.state.prevTime) {
        this.loadDebounceCredit();
      }
    });
  }

  static getDerivedStateFromProps(nextProps: ICurrentProps, prevState: ICurrentState) {
    return {
      prevTime: prevState.newTime,
      newTime: nextProps.time,
    };
  }

  render() {
    return (
      <div>
        {this.state.debounceCreditValue ? (
          <div style={styleDebounce}>
            <p>
              Debounce credit:{' '}
              {this.state.textVisibility ? <span>{this.state.creditOutput}</span> : <span>Loading...</span>}
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

const styleDebounce = {
  fontSize: 10,
  marginLeft: 582,
  marginTop: -15,
};
