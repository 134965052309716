import SIDEBAR from './Sidebars';
import Toolbar from 'material-ui/Toolbar';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import Firebase from './Firebase';
// import utils from './utils';
import './App.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import AppBar from 'material-ui/AppBar';
import TextField from 'material-ui/TextField';
import Logo from './logo_app.png';
import RaisedButton from 'material-ui/RaisedButton';
import { CRUDOOL_SERVER_BASE_URL_DEPRECATED } from './constants';

interface ICurrentProps {}

interface ICurrentState {
  bcUrl: string;
  disabledButton: boolean;
  responseMessage: string;
  displayTextArea: boolean;
}

export default class DBContent extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);

    this.state = {
      bcUrl: '',
      disabledButton: true,
      responseMessage: '',
      displayTextArea: false,
    };
  }

  handleLogout() {
    Firebase.logout();
  }

  updateTextArea(innerHTML: string) {
    const responseTextArea = document.getElementById('responseTextArea');
    if (responseTextArea) {
      responseTextArea.innerHTML = innerHTML;
    }
  }

  handleClick() {
    this.setState({
      disabledButton: true,
      responseMessage: '',
      displayTextArea: false,
    });
    return Firebase.getUserToken().then(token => {
      return fetch(CRUDOOL_SERVER_BASE_URL_DEPRECATED + 'binomCampaignConvertor/?details=' + this.state.bcUrl, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error(`Server responded with error response code (${response.status}/${response.statusText})`);
          }
          return response.json();
        })
        .then(responseJson => {
          this.setState({
            disabledButton: false,
            displayTextArea: true,
          });
          if (responseJson.error) {
            this.setState({
              responseMessage: '<p style=color:red>' + responseJson.error + '</p>',
            });
            this.updateTextArea('<p style=color:red>' + this.state.responseMessage + '</p>');
            console.error('An error occurred!\nReason: ' + responseJson.error);
          } else {
            const wasFound = Number(responseJson.found);
            if (!wasFound) {
              this.setState({
                responseMessage: '<p style=color:red;margin-bottom:0;text-align:left>Campaign not found!</p>',
              });
              this.updateTextArea(this.state.responseMessage);
            } else {
              const customStartDate = new Date(responseJson.start_date * 1000).toString();

              const successMessage = `<p style=margin-bottom:0;text-align:left;color:green>Campaign found!</p>
              <p style=text-align:left>Details:</p>
              <p style=margin-bottom:0;text-align:left><b><u>Binom Campaign</u></b></p>
              <p style=margin-bottom:0;text-align:left><b>ID:</b> ${responseJson.id}</p>
              <p style=margin-bottom:0;text-align:left><b>Name:</b> ${responseJson.name}</p>
              <p style=margin-bottom:0;text-align:left><b>Start date:</b> ${customStartDate}</p>
              <p style=margin-bottom:0;text-align:left><b>Domain:</b> ${responseJson.domain_name}</p>
              <p style=margin-bottom:0;text-align:left><b>Keyword:</b> ${responseJson.keyword}</p>
              <p style=margin-bottom:0;text-align:left><b>Tokens:</b> ${responseJson.tokens_url}</p>
              <p style=margin-bottom:0;text-align:left><b>URL:</b> ${responseJson.url}</p>
              <br><p style=margin-bottom:0;text-align:left><b><u>Traffic source</u></b></p>
              <p style=margin-bottom:0;text-align:left><b>ID:</b> ${responseJson.ts_id}</p>
              <p style=margin-bottom:0;text-align:left><b>Name:</b> ${responseJson.ts_name}</p>
              <br><p style=margin-bottom:0;text-align:left><b><u>Group</u></b></p>
              <p style=margin-bottom:0;text-align:left><b>ID:</b> ${responseJson.group_id}</p>
              <p style=margin-bottom:0;text-align:left><b>Name:</b> ${responseJson.group_name}</p>
              <br><p style=margin-bottom:0;text-align:left><b><u>Rotation</u></b></p>
              <p style=margin-bottom:0;text-align:left><b>ID:</b> ${responseJson.rotation_id}</p>
              <p style=margin-bottom:0;text-align:left><b>Name:</b> ${responseJson.rotation_name}</p>`;
              this.updateTextArea(successMessage);
            }
            console.log(responseJson);
          }
        })
        .catch(error => {
          console.error(`An error occurred!\nReason:\n${error.stack}`);
          this.setState({
            responseMessage: '<p style=color:red;margin-bottom:0;text-align:left>An error has occurred!</p>',
            disabledButton: false,
            displayTextArea: true,
          });
          this.updateTextArea(this.state.responseMessage);
          alert('Error retrieving data from server!');
        });
    });
  }

  handleChange(event: React.FormEvent<unknown>, newValue: string) {
    console.log(newValue);
    if (newValue) {
      this.setState({ bcUrl: newValue, disabledButton: false, displayTextArea: false });
    } else {
      this.setState({ bcUrl: newValue, disabledButton: true, displayTextArea: false });
    }
  }

  render() {
    return (
      <div>
        <MuiThemeProvider>
          <>
            <div id="div">
              <SIDEBAR />
              <Toolbar style={styleToolbar}>
                <img src={Logo} style={styleLogoImg} alt="logo" />
                <h3>Binom Converter</h3>
                <ReactTooltip />

                <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => this.handleLogout()} />
              </Toolbar>
            </div>
            <div>
              <div style={{ textAlign: 'center', marginTop: 10 }}>
                <h6>Enter Binom campaign&apos;s URL, keyword, or ID to get details about the campaign:</h6>
                <TextField
                  hintText="1abgt6yhgftg (for example)" // cSpell:disable-line
                  floatingLabelText="Binom campaign"
                  onChange={(event, newValue) => this.handleChange(event, newValue)}
                  style={styleInputCampaignId}
                  //onKeyDown={(event) => this.handleClick(event)}
                />
                <RaisedButton
                  label="Get details"
                  disabled={this.state.disabledButton}
                  primary={true}
                  onClick={event => this.handleClick()}
                />

                <div style={{ ...styleTextAreaOutside, visibility: this.state.displayTextArea ? 'visible' : 'hidden' }}>
                  <div contentEditable={false} id="responseTextArea" style={styleTextArea}></div>
                </div>
              </div>
            </div>
          </>
        </MuiThemeProvider>
      </div>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh: React.CSSProperties = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};
const styleTextArea = {
  width: '100%',
  height: '100%',
  border: '0px solid #ccc',
  paddingLeft: 10,
  paddingBottom: 10,
};

const styleToolbar = {
  marginTop: -30,
};

const styleTextAreaOutside = {
  padding: 10,
};

const styleInputCampaignId = {
  marginRight: 10,
};
