import * as React from 'react';
import { TRACKER_SERVER_BASE_URL } from '../../constants';
import Firebase from '../../Firebase';
import Loader from '../../loaderImg.gif';
import { Table } from '../../Table';
import utils from '../../utils/utils';

interface IOutgoingPostback {
  loaderVisibility: any;
  results: any;
  tableVisibility: any;
  columns: any;
}

class OutgoingPostBacks extends React.Component<any, IOutgoingPostback> {
  constructor(props: IOutgoingPostback) {
    super(props);
    this.state = {
      columns: [],
      loaderVisibility: true,
      tableVisibility: false,
      results: [],
    };
  }

  componentDidMount() {
    this.outgoingPostbackData();
  }
  row: string[] = [];
  COLUMNS = [
    {
      label: 'Timestamp',
      type: 'string',
      key: 'createdAt',
      // filterable:true
    },
    {
      label: 'Aff ID',
      type: 'string',
      isTableKey: true,
      key: 'affID',
      filterable: true,
    },
    {
      label: 'Aff ClickID',
      key: 'cid',
      filterable: true,
    },
    {
      label: 'Our ClickID',
      key: 'ourCID',
      filterable: true,
    },
    {
      label: 'Flow ID',
      key: 'offerID',
      filterable: true,
    },
    {
      label: 'Brand ID',
      width: '75',
      key: '',
      filterable: true,
    },
    {
      label: 'Payout',
      width: '75',
      key: 'payout',
      filterable: true,
    },

    {
      label: 'Currency',
      width: '75',
      key: 'currency',
      filterable: true,
    },
    {
      label: 'Goal',
      width: '75',
      type: 'string',
      key: 'goal',
      filterable: true,
    },
    {
      label: 'GEO ',
      width: '75',
      type: 'string',
      key: '',
      filterable: true,
    },
    {
      label: 'Postback Response Status',
      width: '100',
      type: 'string',
      key: 'responseStatus',
      filterable: true,
    },
    {
      label: 'Postback Response Body',
      type: 'string',
      key: 'responseBody',
      filterable: true,
    },
  ];

  async outgoingPostbackData() {
    const token = await Firebase.getUserToken();
    try {
      const response = await fetch(TRACKER_SERVER_BASE_URL + 'outgoingPostbackDetails', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      const responseJson = await response.json();
      if (responseJson.error) throw responseJson.error;
      for (const key in responseJson) {
        const obj = responseJson[key];
        //  console.log(new Date(obj.createdAt));
        obj.createdAt = obj.createdAt.replace('Z', ' ').replace('T', ' ');
        // obj.createdAt = new Date(obj.createdAt);

        this.row.push(obj);
      }

      this.setState({
        columns: this.COLUMNS,
        results: this.row,
        loaderVisibility: false,
        tableVisibility: true,
      });
    } catch (error) {
      utils.consoleError(`readableDate - Error: ${utils.convertToString(error)}`);
      alert('Error retrieving data from server!');
    }
  }

  render() {
    return (
      <div>
        <br></br>
        <h4>Outgoing Postbacks</h4>
        {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
        {this.state.tableVisibility ? (
          <div style={tblDiv}>
            <div></div>
            <Table columns={this.state.columns} rows={this.state.results} hasTotalRow={false} />
          </div>
        ) : null}
      </div>
    );
  }
}

// const styleLogoImg = {
//   width: 160,
//   height: '100%'
// }

// const styleRefresh = {
//   marginTop: 25,
//   marginRight: 0,
//   cursor: 'pointer',
//   zIndex: 999
// }

// const styleDateText = {
//   marginTop: 5,
// }

// const styleText = {
//   marginTop: 20,
// }

const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const tblDiv = {
  width: '95%',
  marginTop: '5%',
  marginBottom: '5%',
  marginLeft: '2.5%',
};

// const styleToolbar = {
//   marginTop: -30
// }
export default OutgoingPostBacks;
