import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import Toolbar from 'material-ui/Toolbar';
import { FaUndo } from 'react-icons/fa';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PropTypes, { InferProps, Requireable } from 'prop-types';

import Firebase from '../../Firebase';
import '../../App.css';
import Logo from '../../logo_app.png';
import Loader from '../../loaderImg.gif';
import ServerErrorIcon from '../../cross.png';
import { Table, IColumn } from '../../Table';
import { CRUDOOL_SERVER_BASE_URL } from '../../constants';
import SIDEBAR from '../../Sidebars';
import DebounceCredit from '../../debounceCredit';
import Breadcrumb, { IBreadcrumb } from '../../breadcrumb';
import utils from '../../utils/utils';
import * as dateUtils from '../../utils/dateUtils';
import { useOrganizationContext } from '../../Contexts/OrgContext';
import { CrudoolStatsTable } from 'src/components/CrudoolStatsTable';
import { Switch } from '@material-ui/core';

const propTypesOfComponent = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tracker: PropTypes.string,
      ts: PropTypes.string,
      trackerCampaign: PropTypes.string,
    }),
  }),
};
type IProps = PropTypes.InferProps<typeof propTypesOfComponent>;

export function BasePage(title: string, typeOfObject: number): React.FunctionComponent {
  const BasePageComponent: React.FunctionComponent<IProps> = props => {
    const [time, setTime] = React.useState(Date.now());
    // Relevant only when (typeOfObject === 5)
    const [shouldBeBlurred, setShouldBeBlurred] = React.useState(true);

    const { organization } = useOrganizationContext();

    const firstDateOfThisMonth = dateUtils.outputDateOnlyWithLeadingZeros(dateUtils.beginningOfThisMonth());
    const endDateOfThisMonth = dateUtils.convertEndTimestampToEndDate(dateUtils.endOfThisMonth());
    const [fromDateElementValue, setFromDateElementValue] = React.useState(`${firstDateOfThisMonth}T00:00`);
    const [toDateElementValue, setToDateElementValue] = React.useState(`${endDateOfThisMonth}T23:59`);
    const [actualDatesToFilter, setActualDatesToFilter] = React.useState<{ from: string; to: string }>({
      from: fromDateElementValue,
      to: toDateElementValue,
    });
    // console.log('fromDateElementValue=' + fromDateElementValue + '\ntoDateElementValue=' + toDateElementValue);

    // console.log('props=' + utils.convertToString(props));

    let refreshFunction = undefined;
    if (organization) {
      refreshFunction = (event: unknown) => {
        setTime(Date.now());
        setActualDatesToFilter({
          from: fromDateElementValue,
          to: toDateElementValue,
        });
      };
    }

    let dataTables;
    if (typeOfObject === 5) {
      utils.consoleLog('shouldBeBlurred='+shouldBeBlurred);
      dataTables = (
        <div>
          <h1>Banners</h1>
          <Switch
            checked={shouldBeBlurred}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              utils.consoleLog('checked=' + checked);
              setShouldBeBlurred(checked);
            }}
          />Blur images
          <CrudoolStatsTable
            typeOfObject={5.1}
            fromDateElementValue={actualDatesToFilter.from}
            toDateElementValue={actualDatesToFilter.to}
            params={props.match?.params}
            time={time}
            shouldBeBlurred={shouldBeBlurred}
          />
          <br />
          <h1>Targets</h1>
          <CrudoolStatsTable
            typeOfObject={5.2}
            fromDateElementValue={actualDatesToFilter.from}
            toDateElementValue={actualDatesToFilter.to}
            params={props.match?.params}
            time={time}
          />
          <br />
          <h1>Zones</h1>
          <CrudoolStatsTable
            typeOfObject={5.3}
            fromDateElementValue={actualDatesToFilter.from}
            toDateElementValue={actualDatesToFilter.to}
            params={props.match?.params}
            time={time}
          />
        </div>
      );
    } else {
      dataTables = (
        <CrudoolStatsTable
          typeOfObject={typeOfObject}
          fromDateElementValue={actualDatesToFilter.from}
          toDateElementValue={actualDatesToFilter.to}
          params={props.match?.params}
          time={time}
        />
      );
    }

    return (
      <div>
        <MuiThemeProvider>
          <div id="div">
            <SIDEBAR />
            <Toolbar style={styleToolbar}>
              <img src={Logo} style={styleLogoImg} alt="logo" />
              <h3>{title}</h3>
              {refreshFunction && <FaUndo data-tip="Refresh" style={styleRefresh} onClick={refreshFunction} />}
              <ReactTooltip />
              <label style={styleText}>From:</label>
              <TextField
                style={styleDateText}
                id="datetime-local-from"
                value={fromDateElementValue}
                /*label="Next appointment"*/ type="datetime-local"
                onChange={(e: unknown, newValue: string) => {
                  console.log('newValue=' + newValue);
                  setFromDateElementValue(newValue);
                }}
              />
              <label style={styleText}>To:</label>
              <TextField
                style={styleDateText}
                id="datetime-local-to"
                value={toDateElementValue}
                /*label="Next appointment"*/ type="datetime-local"
                onChange={(e: unknown, newValue: string) => {
                  console.log('newValue=' + newValue);
                  setToDateElementValue(newValue);
                }}
              />

              <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => Firebase.logout()} />
            </Toolbar>
            <DebounceCredit time={time} />
          </div>
        </MuiThemeProvider>
        {dataTables}
      </div>
    );
  };

  BasePageComponent.displayName = `Page${typeOfObject}`;
  BasePageComponent.propTypes = propTypesOfComponent;
  return BasePageComponent;
}

const styleLogoImg: React.CSSProperties = {
  width: 160,
  height: '100%',
};

const styleRefresh: React.CSSProperties = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};

const styleDateText: React.CSSProperties = {
  marginTop: 5,
};

const styleText: React.CSSProperties = {
  marginTop: 20,
};

const styleToolbar: React.CSSProperties = {
  marginTop: -30,
};
