import PropTypes from 'prop-types';
import React, { createContext, useState, useEffect, useContext, useMemo, FunctionComponent } from 'react';
import utils from '../utils/utils';
import * as Firebase from '../Firebase';

interface IOrgData {
  name: string;
  id: string;
}
interface IOrganizationContext {
  loading: boolean;
  organization: null | IOrgData;
}

const OrganizationContext = createContext<IOrganizationContext>({
  loading: true,
  organization: null,
});

export const OrganizationProvider: FunctionComponent = ({ children }) => {
  const [organization, setOrganization] = useState<null | IOrgData>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    Firebase.getOrgRolesOfUser().then(orgRoles => {
      switch (orgRoles.length) {
        case 0:
          utils.consoleError(`Dashboard.loadOrgDetails - Error: The user has no orgRoles!`);
          setOrganization(null);
          setLoading(false);
          break;

        default:
          utils.consoleError(`Dashboard.loadOrgDetails - Error: The user has more than 1 orgRole!`);
        // falls through to the next 'case'

        case 1:
          setOrganization({
            id: orgRoles[0].orgId,
            name: '...',
          });
          setLoading(false);
          break;
      }
    });
  }, []);

  if (loading) {
    return <div>Loading organization...</div>;
  }

  return (
    <OrganizationContext.Provider
      value={{
        organization,
        loading,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

OrganizationProvider.propTypes = {
  children: PropTypes.element,
};

export function useOrganizationContext() {
  return useContext(OrganizationContext);
}
