import React from 'react';
import { Table as TABLE1, ITable } from './Table';
import { Table as TABLE2 } from './Table';
import TsTarget from './TsTarget';
import { ITarget } from './TsTargetDailyStatsTable';

interface ICurrentProps {
  counter: number;
  tsCampaignDataFull: ITable<unknown>;
  tsCampaignDataBanner: ITable<unknown>;
  tsCampaignDataTargets: ITable<ITarget>;
}

interface ICurrentState {
  tsCampaignLink: string;
  tsCampaignDisplay: string;
  tsCampaignData: unknown[];
  tsCampaignDataTableRows: unknown[];
  tsCampaignDataTableColumns: unknown[];
}

export default class TsCampaign extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      tsCampaignData: [],
      tsCampaignLink: '',
      tsCampaignDisplay: '',
      tsCampaignDataTableRows: [],
      tsCampaignDataTableColumns: [],
    };
  }

  render() {
    const _rows = [];
    _rows.push(this.props.tsCampaignDataFull.rows[this.props.counter]);
    // delete _rows[0].childrenTargets;
    // delete _rows[0].childrenBanners;
    const bannerRows = [];
    for (let i = 0; i < this.props.tsCampaignDataBanner.rows.length; i++) {
      bannerRows.push(this.props.tsCampaignDataBanner.rows[i]);
    }
    bannerRows.push(this.props.tsCampaignDataBanner.totals);

    console.log(this.props.tsCampaignDataFull);

    return (
      <div style={styleDiv}>
        <div style={stylePad}>
          <a href={this.state.tsCampaignLink}>{this.state.tsCampaignDisplay}</a>
          <div>
            <TABLE1 columns={this.props.tsCampaignDataFull.columns} rows={_rows} />
          </div>
          {this.props.tsCampaignDataTargets.rows.map((result, index) => {
            return (
              <div key={index}>
                <p>Targets:</p>
                <TsTarget
                  tsTargetData={result}
                  index={index}
                  tsTargetFull={this.props.tsCampaignDataTargets}
                  tsTargetDailyStat={this.props.tsCampaignDataTargets.rows[index].childrenDailyStats}
                />
              </div>
            );
          })}
          <p>Banners:</p>
          <div>
            <TABLE2 columns={this.props.tsCampaignDataBanner.columns} rows={bannerRows} />
          </div>
        </div>
      </div>
    );
  }
}

const styleDiv = {
  backgroundColor: '#f2f2f2',
  marginLeft: 20,
  //marginRight:20,
  marginTop: 20,
};

const stylePad = {
  padding: 20,
};
