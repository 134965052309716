import React from 'react';
import Toolbar from 'material-ui/Toolbar';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { Route, Switch } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import PageNotFound from '../../page-not-found.jpg';
import SIDEBAR from '../../Sidebars';
import Logo from '../../logo_app.png';
import Firebase from '../../Firebase';
import Affiliates from './Affiliates';
import IncomingPostBacks from './IncomingPostBacks';
import OutgoingPostBacks from './OutgoingPostbacks';
import utils from '../../utils/utils';
import { TRACKER_SERVER_BASE_URL } from '../../constants';
import Statistics from './Statistics';

interface IProps {
  match: {
    path: string;
  };
  location: {
    pathname: string;
  };
}

const StatisticsComponent = () => (
  <div>
    {' '}
    <Statistics />
  </div>
);
const AffiliatesComponent = () => (
  <div>
    {' '}
    <Affiliates />
  </div>
);
const ClicksComponent = () => (
  <div>
    {' '}
    <img src={PageNotFound} style={styleNotFound} alt="PageNotFound" />
  </div>
);
const IncomingPostbacksComponent = () => (
  <div>
    {' '}
    <IncomingPostBacks />
  </div>
);
const OutgoingPostbacksComponent = () => (
  <div>
    {' '}
    <OutgoingPostBacks />
  </div>
);
const FlowsComponent = () => (
  <div>
    {' '}
    <img src={PageNotFound} style={styleNotFound} alt="PageNotFound" />
  </div>
);

const OffersComponent = () => (
  <div>
    <img src={PageNotFound} style={styleNotFound} alt="PageNotFound" />
  </div>
);
const FlowAssignmentComponent = () => (
  <div>
    {' '}
    <img src={PageNotFound} style={styleNotFound} alt="PageNotFound" />
  </div>
);

export default class Tracker extends React.Component<IProps> {
  handleLogout() {
    Firebase.logout();
  }

  componentDidMount() {
    this.checkVersions();
  }

  async checkVersions() {
    const token = await Firebase.getUserToken();
    try {
      const response = await fetch(TRACKER_SERVER_BASE_URL + 'getVersionNumber', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      utils.consoleLog('FE = ' + 3);
      if (response.ok) {
        const responseJson = await response.json();
        utils.consoleLog('BE = ' + responseJson);
      } else {
        utils.consoleError(`getVersionNumber (BE) - Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      utils.consoleError(`getVersionNumber - Error: ${utils.convertToString(error)}`);
      // alert("Error retrieving data from server!");
    }
  }
  render() {
    const { path } = this.props.match;
    const { pathname } = this.props.location;

    return (
      <div>
        <MuiThemeProvider>
          <div id="div">
            <SIDEBAR />
            <Toolbar style={styleToolbar}>
              <img src={Logo} style={styleLogoImg} alt="logo" />

              <ReactTooltip />

              <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={() => this.handleLogout()} />
            </Toolbar>
          </div>
        </MuiThemeProvider>
        <div>
          <div>
            <h1>Tracker Page</h1>
            <div className="TrackerLinks">
              <NavLink
                to={`${path}/Statistics` || `${path}`}
                className={'TrackerLink ' + (pathname === `${path}` ? 'active' : '')}
              >
                Statistics
              </NavLink>
              <NavLink to={`./Affiliates`} className="TrackerLink">
                Affiliates
              </NavLink>
              <NavLink to={`${path}/Clicks`} className="TrackerLink">
                Clicks/Leads
              </NavLink>
              <NavLink to={`${path}/IncomingPostbacks`} className="TrackerLink">
                IncomingPostbacks
              </NavLink>
              <NavLink to={`${path}/OutgoingPostbacks`} className="TrackerLink">
                OutgoingPostbacks
              </NavLink>
              <NavLink to={`${path}/Flows`} className="TrackerLink">
                Flows
              </NavLink>
              <NavLink to={`${path}/Offers`} className="TrackerLink">
                Offers
              </NavLink>
              <NavLink to={`${path}/FlowAssignment`} className="TrackerLink">
                FlowAssignment
              </NavLink>
            </div>
            <div className="tabs">
              <Switch>
                <Route path={`${path}`} exact component={StatisticsComponent} />
                <Route path={`${path}/Statistics`} exact component={StatisticsComponent} />
                <Route path={`${path}/Affiliates`} exact component={AffiliatesComponent} />
                <Route path={`${path}/Clicks`} exact component={ClicksComponent} />
                <Route path={`${path}/IncomingPostbacks`} exact component={IncomingPostbacksComponent} />
                <Route path={`${path}/Flows`} exact component={FlowsComponent} />
                <Route path={`${path}/OutgoingPostbacks`} exact component={OutgoingPostbacksComponent} />
                <Route path={`${path}/Offers`} exact component={OffersComponent} />
                <Route path={`${path}/FlowAssignment`} exact component={FlowAssignmentComponent} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
};

const styleNotFound = {
  width: '50%',
  height: 300,
  marginLeft: 300,
  marginTop: 20,
};

const styleToolbar = {
  marginTop: -30,
};
