import * as React from 'react';
import Firebase from '../../Firebase';
import Loader from '../../loaderImg.gif';
import { Table } from '../../Table';
import { FaEdit } from 'react-icons/fa';
import { RiDeleteBin2Line } from 'react-icons/ri';
import utils from '../../utils/utils';
import { TRACKER_SERVER_BASE_URL } from '../../constants';

interface IAffiliate {
  confirm: any;
  loaderVisibility: any;
  results: any;
  tableVisibility: any;
  columns: any;
}

class Affiliates extends React.Component<any, IAffiliate> {
  constructor(props: IAffiliate) {
    super(props);
    this.state = {
      confirm: '',
      columns: [],
      loaderVisibility: true,
      tableVisibility: false,
      results: [],
    };
  }

  componentDidMount() {
    this.getAffiliateDetails();
  }

  row: string[] = [];
  COLUMNS = [
    {
      label: 'ID',
      width: '120',
      type: 'string',
      isTableKey: true,
      key: 'affID',
      filterable: true,
    },
    {
      label: 'Affiliate Name',
      type: 'string',
      action: 'nameOrEmail',
      filterable: true,
      key: 'name',
      key2: 'email',
    },
    {
      label: 'Account Manager',
      key: 'manager',
      filterable: true,
    },
    { label: 'Tags', key: 'tags', filterable: true },
    {
      label: 'Registration date',
      width: '120',
      type: 'string',
      key: 'createdAt',
      filterable: true,
    },
    {
      label: 'Edit',
      type: 'edit',
      width: '75',
      key: '_id',
      dataFormat: 'icon',
      actions: this.editActionFormatter,
    },
    {
      label: 'Delete',
      type: 'delete',
      width: '75',
      key: '_id',
      dataFormat: 'icon',
      actions: this.deleteActionFormatter.bind(this),
    },
  ];

  editActionFormatter(cell: any, row: any) {
    return (
      <FaEdit
        className="TrackerClickable"
        onClick={() => {
          //  console.log(row._id);
        }}
      />
    );
  }

  deleteActionFormatter(cell: any, row: any) {
    return (
      <RiDeleteBin2Line
        className="TrackerClickable"
        onClick={() => {
          const name = row.name || row.email;
          const id = row.affID;

          if (window.confirm(`Are you sure that you want to permanently delete affiliate ${name} ?`) === true) {
            this.deleteAffiliate(id);
          }
        }}
      />
    );
  }

  deleteAffiliate(id: any) {
    const that = this;
    return Firebase.getUserToken().then(token => {
      return fetch(TRACKER_SERVER_BASE_URL + 'deleteAffiliate?affId=' + id, {
        method: 'PUT',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(
          (response: any) => utils.debugLog(`readableDate - Response: ${utils.convertToString(response)}`),
          window.confirm('Successfully deleted Affiliate ' + id) ? null : null
        )
        .catch(error => {
          utils.consoleError(`readableDate - Error: ${error.stack}`);
          alert('Could not delete');
        })
        .finally(() => that.getAffiliateDetails());
    });
  }

  async getAffiliateDetails() {
    const token = await Firebase.getUserToken();
    try {
      const response = await fetch(TRACKER_SERVER_BASE_URL + 'AffiliateDetails', {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      const responseJson = await response.json();
      if (responseJson.error) throw responseJson.error;
      for (const key in responseJson) {
        const obj = responseJson[key];
        if (!obj.name) {
          obj.name = obj.email;
        }
        obj.createdAt = obj.createdAt.slice(0, 10);
        this.row.push(obj);
      }

      this.setState({
        columns: this.COLUMNS,
        results: responseJson,
        loaderVisibility: false,
        tableVisibility: true,
      });
    } catch (error) {
      utils.consoleError(`readableDate - Error: ${utils.convertToString(error)}`);
      alert('Error retrieving data from server!');
    }
  }

  render() {
    return (
      <div>
        <br></br>
        <h4>Affiliates Listing</h4>

        <br></br>
        {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
        {this.state.tableVisibility ? (
          <div style={tblDiv}>
            <div></div>
            <Table columns={this.state.columns} rows={this.state.results} hasTotalRow={false} />
          </div>
        ) : null}
      </div>
    );
  }
}

// const styleLogoImg = {
//   width: 160,
//   height: '100%'
// }

// const styleRefresh = {
//   marginTop: 25,
//   marginRight: 0,
//   cursor: 'pointer',
//   zIndex: 999
// }

// const styleDateText = {
//   marginTop: 5,
// }

// const styleText = {
//   marginTop: 20,
// }

const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const tblDiv = {
  width: '95%',
  marginTop: '5%',
  marginLeft: '2.5%',
};

// const styleToolbar = {
//   marginTop: -30
// }
export default Affiliates;
