/* eslint-disable */
import React, { ReactElement } from 'react';
import { BootstrapTable, SortOrder, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import utils from './utils/utils';

interface cellWithLink {
  link: string;
  display: string;
}

enum COLUMN_TYPE {
  STRING = 'string',
  MEDIA = 'media',
}

enum BANNER_TYPE {
  IMAGE = 'I',
  PREROLL = 'P',
  DIRECT_LINK = 'D',
  ERR = 'Error!',
  BANNER_NOT_FOUND = '-',
}

export interface IColumn {
  type: COLUMN_TYPE;
  filterable: boolean;
  sortable: boolean;
  isTableKey: boolean;
  width: number | string;
  widthInPx: number;
  key: string;
  label: string;
  filterPlaceholder: string;
}

export interface ITable<T> {
  rows: T[];
  totals?: T;
  columns: IColumn[];
}

interface ICurrentProps {
  columns: IColumn[];
  rows: any[];
  hasTotalRow?: boolean; // Default = true!
  shouldBeBlurred?: boolean | null,
}

interface ICurrentState {
  hasTotalRow: boolean;
  shouldBeBlurred: boolean;
}

export class Table extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    let _hasTotalRow: boolean = true;
    if ('hasTotalRow' in props) {
      _hasTotalRow = props.hasTotalRow!;
    }
    utils.consoleLog(utils.convertToString(props));
    utils.consoleLog(utils.convertToString(this.props));
    this.state = {
      hasTotalRow: _hasTotalRow,
      shouldBeBlurred: props && props.shouldBeBlurred !== undefined && props.shouldBeBlurred !== null ? props.shouldBeBlurred : true,
    };

    this.CellFormatter = this.CellFormatter.bind(this);
    this.rowStyleFormat = this.rowStyleFormat.bind(this);
  }

  CellFormatter(this: Table, cell: string | cellWithLink): JSX.Element {
    if (typeof cell === 'object') {
      if (cell) {
        return (
          <div data-tip={cell.display}>
            <a href={cell.link}>{cell.display}</a>
          </div>
        );
      } else {
        return <div>ERR</div>;
      }
    } else {
      return <span data-tip={cell}>{cell}</span>;
    }
  }

  getCaret(direction: SortOrder | null, fieldName: string): string | ReactElement {
    return direction === 'asc' ? <FaCaretUp /> : <FaCaretDown />;
  }

  revertSortFunc(a: any, b: any, order: SortOrder, key: string, extraData: unknown): number {
    const result = Object.values(a);

    if (typeof a[key] !== 'string' && typeof result[0] === 'object') {
      if (a[key].display) {
        if (order === 'desc') {
          return a[key].display.localeCompare(b[key].display);
        } else {
          return b[key].display.localeCompare(a[key].display);
        }
      } else {
        if (order === 'desc') {
          return a[key] - b[key];
        } else {
          return b[key] - a[key];
        }
      }
    }
    // if (typeof a[key] === "string" && typeof result[0] === "object") {
    if (order === 'desc') {
      return a[key].localeCompare(b[key]);
    } else {
      return b[key].localeCompare(a[key]);
    }
    // }
  }

  imageFormatter(
    cell: any,
    row: any,
    formatExtraData: any,
    rowIndex: number
  ): string | React.ReactElement<any, string | React.JSXElementConstructor<any>> {
    if (cell && cell.type && (cell.type === BANNER_TYPE.IMAGE || cell.type === 'IMAGE')) {
      const theStyles: React.CSSProperties = { width: '70%', height: '20%' };
      utils.consoleLog('this.state='+utils.convertToString(this.state));
      if (this.state?.shouldBeBlurred) {
        theStyles.filter = 'blur(15px)';
      }
      return (
        <img
          style={theStyles}
          alt="data"
          src={cell.url}
          onError={(event: any) => {
            console.error(`Couldn't find '${event?.target?.src}' that should have been loaded into: `, event?.target);
            event.target.onError = null;
            event.target.src = 'https://static.dailycasualhookup.com/img/false-delete-remove-cross-wrong-36-32770.webp';
          }}
        />
      );
    } else {
      // throw new Error(`Not an image! ${utils.convertToString(cell)}`);
      console.error(`Not an image! ${utils.convertToString(cell)}`);
      return '';
    }
  }

  rowStyleFormat(this: Table, rowData: unknown, rowIndex: number): React.CSSProperties {
    const isLastRow: boolean = rowIndex === this.props.rows.length - 1;
    const hasMoreThan1Row: boolean = this.props.rows.length > 1;
    if (hasMoreThan1Row && isLastRow && this.state.hasTotalRow) {
      return styleTotalRow;
    } else {
      return styleNormalRow;
    }
  }

  render() {
    return (
      <div style={styleT}>
        <BootstrapTable
          data={this.props.rows}
          striped
          hover
          condensed
          headerStyle={{ backgroundColor: '#66708B', fontSize: 13 }}
          trStyle={this.rowStyleFormat}
          // wrapperClasses="table-responsive"
        >
          {this.props.columns.map(value => {
            value.width = value.widthInPx ? value.widthInPx + 'px' : '200px';
            if (value['type'] === 'string' && value['filterable'] === true && value['sortable'] === true) {
              return (
                <TableHeaderColumn
                  isKey={value['isTableKey'] || false}
                  width={value['width'] ? '' + value['width'] : '200'}
                  dataFormat={this.CellFormatter}
                  caretRender={this.getCaret}
                  dataSort={true}
                  sortFunc={this.revertSortFunc}
                  key={value['key']}
                  dataField={value['key']}
                >
                  {value['label']}
                </TableHeaderColumn>
              );
            }
            if (value['type'] === 'media' && value['filterable'] === false && value['sortable'] === false) {
              return (
                <TableHeaderColumn
                  isKey={value['isTableKey'] || false}
                  width={value['width'] ? '' + value['width'] : '200'}
                  dataFormat={this.imageFormatter}
                  dataField={value['key']}
                  key={value['key']}
                >
                  {value['label']}
                </TableHeaderColumn>
              );
            }
            if (value['filterable'] === true && value['sortable'] === true) {
              return (
                <TableHeaderColumn
                  isKey={value['isTableKey'] || false}
                  width={value['width'] ? '' + value['width'] : '200'}
                  dataFormat={this.CellFormatter}
                  filter={{ type: 'TextFilter', placeholder: value['filterPlaceholder'], style: { fontSize: 13 } }}
                  caretRender={this.getCaret}
                  dataSort={true}
                  sortFunc={this.revertSortFunc}
                  key={value['key']}
                  dataField={value['key']}
                >
                  {value['label']}
                </TableHeaderColumn>
              );
            }
            if (value['filterable'] === true && value['sortable'] !== true) {
              return (
                <TableHeaderColumn
                  isKey={value['isTableKey'] || false}
                  width={value['width'] ? '' + value['width'] : '200'}
                  dataFormat={this.CellFormatter}
                  filter={{ type: 'TextFilter', placeholder: value['filterPlaceholder'], style: { fontSize: 13 } }}
                  key={value['key']}
                  dataField={value['key']}
                >
                  {value['label']}
                </TableHeaderColumn>
              );
            }
            if (value['sortable'] === true && value['filterable'] !== true) {
              return (
                <TableHeaderColumn
                  isKey={value['isTableKey'] || false}
                  width={value['width'] ? '' + value['width'] : '200'}
                  dataFormat={this.CellFormatter}
                  caretRender={this.getCaret}
                  dataSort={true}
                  sortFunc={this.revertSortFunc}
                  key={value['key']}
                  dataField={value['key']}
                >
                  {value['label']}
                </TableHeaderColumn>
              );
            }
            return (
              <TableHeaderColumn
                isKey={value['isTableKey'] || false}
                width={value['width'] ? '' + value['width'] : '200'}
                dataFormat={this.CellFormatter}
                key={value['key']}
                dataField={value['key']}
              >
                {value['label']}
              </TableHeaderColumn>
            );
          })}
        </BootstrapTable>
        <ReactTooltip />
      </div>
    );
  }
}

const styleT = {
  backgroundColor: 'white',
};

const styleTotalRow: React.CSSProperties = {
  backgroundColor: '#4A526A',
  color: 'white',
  // title: "kkk"
};

const styleNormalRow: React.CSSProperties = {};
