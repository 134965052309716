import React from 'react';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import Toolbar from 'material-ui/Toolbar';
import { FaUndo } from 'react-icons/fa';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Firebase from '../../Firebase';
import '../../App.css';
import Logo from '../../logo_app.png';
import Loader from '../../loaderImg.gif';
import ServerErrorIcon from '../../cross.png';
import { Table } from '../../Table';
import { CRUDOOL_SERVER_BASE_URL_DEPRECATED } from '../../constants';
import SIDEBAR from '../../Sidebars';
import DebounceCredit from '../../debounceCredit';
import Breadcrumb, { IBreadcrumb } from '../../breadcrumb';
import utils from 'src/utils/utils';

interface ICurrentProps {}

interface ICurrentState {
  breadcrumbs: IBreadcrumb[];
  fromDateResponse: string;
  toDateResponse: string;
  loaderVisibility: boolean;
  tableVisibility: boolean;
  serverError: boolean;
  // data: any[],
  rows: unknown[];
  columns: [];
  time: number;
}

// // let lastCol = 0;

export default class Dashboard extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      breadcrumbs: [],
      rows: [],
      loaderVisibility: true,
      tableVisibility: false,
      serverError: false,
      time: Date.now(),
      fromDateResponse: '',
      toDateResponse: '',
      columns: [],
    };
    console.log(`this.state=${utils.convertToString(this.state)}`);
  }

  handleLogout() {
    console.log('Logging out...');
    Firebase.logout();
    // .then(
    //   () => {
    //     // this.props.history.push("/login");
    //   }
    // )
    // .catch(() => {

    // });
  }

  loadTSData(fromDate: string, toDate: string) {
    console.log(`${fromDate}-${toDate}`);
    this.setState({
      loaderVisibility: true,
      tableVisibility: false,
    });
    if (fromDate === '' || toDate === '') {
      alert('From & To date cannot be empty!');
      return;
    }
    return Firebase.getUserToken().then(token => {
      return fetch(`${CRUDOOL_SERVER_BASE_URL_DEPRECATED}dashboard/performance?fromDate=${fromDate}&toDate=${toDate}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => response.json())
        .then(responseJson => {
          if (responseJson.error) throw responseJson.error;
          // let colObj: {} | null = null;
          const resultOutput = [...responseJson.table.rows, responseJson.table.totals];
          // const breadcrumbOutput = [];

          // for (let x = 0; x < responseJson.breadcrumbs.length; x++) {
          //   breadcrumbOutput.push(responseJson.breadcrumbs[x]);
          // }
          // for (let i = 0; i < responseJson.table.rows.length; i++) {
          //   // colObj = {};
          //   resultOutput.push(responseJson.table.rows[i]);
          // }

          // lastCol = 0;
          // // for (let j = 0; j < 1; j++) {
          //   // colObj = {};
          //   for (let k = 0; k < responseJson.table.totals.length; k++) {
          //     // colObj[k] = responseJson.table.totals[k];
          //     lastCol = k;
          //   }
          //   // colObj[lastCol + 1] = "Count Row";
          //   // resultOutput.push(responseJson.table.totals);
          // // }
          this.setState({
            columns: responseJson.table.columns,
            rows: resultOutput,
            loaderVisibility: false,
            tableVisibility: true,
            fromDateResponse: responseJson.fromDate,
            toDateResponse: responseJson.toDate,
            breadcrumbs: responseJson.breadcrumbs,
          });
        })
        .catch(error => {
          console.error(error);
          alert('Error retrieving data from server!');
          this.setState({
            serverError: true,
            loaderVisibility: false,
          });
        });
    });
  }

  handleRefresh() {
    const fromDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-from'
    ) as HTMLInputElement | null;
    const toDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-to'
    ) as HTMLInputElement | null;
    const fromDate: string = (fromDateElement && fromDateElement.value) || '';
    const toDate: string = (toDateElement && toDateElement.value) || '';
    this.loadTSData(fromDate, toDate);
    this.setState({
      time: Date.now(),
    });
  }

  componentDidMount() {
    console.log('this.props=' + utils.convertToString(this.props));
    const today = new Date();
    const thisMonthParsed = today.getMonth() + 1;
    const thisMonth: string = (thisMonthParsed < 10 ? `0` : ``) + thisMonthParsed;
    const thisDate = (today.getDate() < 10 ? '0' : '') + today.getDate();

    const fromDate = `${today.getFullYear()}-${thisMonth}-01T00:00`;
    const toDate = `${today.getFullYear()}-${thisMonth}-${thisDate}T23:59`;

    const fromDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-from'
    ) as HTMLInputElement | null;
    const toDateElement: HTMLInputElement | null = document.getElementById(
      'datetime-local-to'
    ) as HTMLInputElement | null;
    if (fromDateElement) fromDateElement.value = fromDate;
    if (toDateElement) toDateElement.value = toDate;
    this.loadTSData(fromDate, toDate);
  }

  // CellFormatter(cell, row) {
  //   if (typeof row != "object") {
  //     return (<div><a href={"/#2/" + row}>{cell}</a></div>);
  //   } else {
  //     return (<div>{cell}</div>);
  //   }
  // }

  render() {
    // console.log(`this.state2=${utils.convertToString(this.state)}`);
    return (
      <div>
        <MuiThemeProvider>
          <div id="div">
            <SIDEBAR />
            <Toolbar style={styleToolbar}>
              <img src={Logo} style={styleLogoImg} alt="logo" />
              <h3>Traffic Sources - Overview</h3>
              <FaUndo data-tip="Refresh" style={styleRefresh} onClick={event => this.handleRefresh()} />
              <ReactTooltip />
              <label style={styleText}>From:</label>
              <TextField
                style={styleDateText}
                id="datetime-local-from"
                /*label="Next appointment"*/ type="datetime-local"
              />
              <label style={styleText}>To:</label>
              <TextField
                style={styleDateText}
                id="datetime-local-to"
                /*label="Next appointment"*/ type="datetime-local"
              />

              <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => this.handleLogout()} />
            </Toolbar>
            {this.state.tableVisibility ? <DebounceCredit time={this.state.time} /> : null}
          </div>
        </MuiThemeProvider>
        {this.state.loaderVisibility ? <img src={Loader} style={styleLoaderImg} alt="loader" /> : null}
        {this.state.tableVisibility ? (
          <div style={tblDiv}>
            <div>
              <Breadcrumb
                breadcrumbs={this.state.breadcrumbs}
                fromDateResponse={this.state.fromDateResponse}
                toDateResponse={this.state.toDateResponse}
              />
            </div>
            <Table columns={this.state.columns} rows={this.state.rows} />
          </div>
        ) : null}
        {this.state.serverError ? <img src={ServerErrorIcon} style={styleServerErrorImg} alt="ServerError" /> : null}
      </div>
    );
  }
}

const styleLogoImg: React.CSSProperties = {
  width: 160,
  height: '100%',
};

const styleRefresh: React.CSSProperties = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};

const styleDateText: React.CSSProperties = {
  marginTop: 5,
};

const styleText: React.CSSProperties = {
  marginTop: 20,
};

const styleLoaderImg: React.CSSProperties = {
  width: 70,
  height: 70,
  marginLeft: 550,
};

const tblDiv: React.CSSProperties = {
  width: '95%',
  marginTop: '5%',
  marginLeft: '2.5%',
  zIndex: -999,
};

const styleToolbar: React.CSSProperties = {
  marginTop: -30,
};

const styleServerErrorImg: React.CSSProperties = {
  width: '50%',
  height: 300,
  marginLeft: 300,
  marginTop: 20,
};
