import SIDEBAR from '../Sidebars';
import Toolbar from 'material-ui/Toolbar';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import Firebase from '../Firebase';
// import utils from '../utils';
import '../App.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Logo from '../logo_app.png';
// import Loader from '../loaderImg.gif';
// import CRUDOOL_SERVER_BASE_URL_DEPRECATED from '../constants';
import { CSSProperties } from 'react';

interface ICurrentProps {}

interface ICurrentState {
  tracker: string;
  adv: string;
  currency: string;
  pep: PEP_STATES;
  goal: string;
  payout: '' | number;
}

const CURRENCY_DEFAULT_VALUE = 'REPLACE_WITH_CURRENCY';
const PAYOUT_DEFAULT_VALUE = 'REPLACE_WITH_PAYOUT_AMOUNT';
const GOAL_DEFAULT_VALUE = 'REPLACE_WITH_GOAL_NAME';

enum PEP_STATES {
  NOT_SELECTED = 'ns',
  FALSE = 'f',
  TRUE = 't',
}

export default class postbackGenerator extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: ICurrentProps) {
    super(props);

    this.state = {
      tracker: '',
      adv: '',
      currency: CURRENCY_DEFAULT_VALUE,
      pep: PEP_STATES.NOT_SELECTED,
      goal: '',
      payout: '',
    };
  }

  handleLogout() {
    Firebase.logout();
  }

  componentDidMount() {
    this.setState({
      // isLoading: true,
      // incidentList: [],
    });
    // return Firebase.getUserToken().then(token => {
    //   return Promise.all([
    //     this.getIncidents(token),
    //     getInfoAboutPerformanceData(),
    //   ]).then((data) => {
    //     const incidents: ISAMI_Incident[] = data[0];
    //     const info: IInfoAboutPerformanceData = data[1];
    //     this.setState({
    //       isLoading: false,
    //       hasError: false,
    //       incidentList: incidents,
    //       infoAboutPerformanceData: info,
    //     });
    //   }).catch(e => {
    //     console.error(e.stack);
    //     this.setState({
    //       isLoading: false,
    //       hasError: true,
    //     });
    //   });
    // });
  }

  changeTracker(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      tracker: event.target.value,
    });
  }

  changeAdv(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      adv: event.target.value,
    });
  }

  changeCurrency(event: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      currency: event.target.value,
    });
  }

  changePEP(event: React.ChangeEvent<HTMLSelectElement>) {
    if ((Object.values(PEP_STATES) as string[]).includes(event.target.value)) {
      this.setState({
        pep: event.target.value as PEP_STATES,
      });
    } else {
      this.setState({
        pep: PEP_STATES.NOT_SELECTED,
      });
    }
  }

  changeGoal(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      goal: event.target.value,
    });
  }

  changePayout(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      payout: event.target.value as unknown as number,
    });
  }

  clickCopyButton(event: React.MouseEvent<HTMLButtonElement>) {
    const result = document.getElementById('result');
    if (result) {
      const resultText = result.innerText;
      console.log(resultText);
      const textarea = document.createElement('textarea');
      textarea.textContent = resultText;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
      textarea.style.display = 'hidden';
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    } else {
      throw new Error(`clickCopyButton - Error: result not found`);
    }
  }

  render() {
    let pep = <span>{''}</span>;
    switch (this.state.pep) {
      case PEP_STATES.NOT_SELECTED:
      case PEP_STATES.FALSE:
      default:
        pep = <span>{''}</span>;
        break;

      case PEP_STATES.TRUE:
        pep = (
          <span>
            {'&pep='}
            <span style={{ color: 'orange', fontWeight: 'bold' }}>{`y`}</span>
          </span>
        );
        break;
    }
    const tracker = <span style={{ color: 'red', fontWeight: 'bold' }}>{this.state.tracker}</span>;
    const adv = <span style={{ color: 'green', fontWeight: 'bold' }}>{this.state.adv}</span>;
    const currency = <span style={{ color: 'blue', fontWeight: 'bold' }}>{this.state.currency}</span>;
    const goal = (
      <span style={{ color: 'purple', fontWeight: 'bold' }}>
        {this.state.goal ? this.state.goal : GOAL_DEFAULT_VALUE}
      </span>
    );
    const payout = (
      <span style={{ color: 'teal', fontWeight: 'bold' }}>
        {this.state.payout ? this.state.payout : PAYOUT_DEFAULT_VALUE}
      </span>
    );
    const linkComponent =
      this.state.tracker && this.state.adv && this.state.pep !== PEP_STATES.NOT_SELECTED ? (
        <div>
          <div id="result" style={resultStyle}>
            <span style={{ whiteSpace: 'pre' }}>{`https://magicformulamad.herokuapp.com/postback?trk=`}</span>
            {tracker}
            {pep}
            {`&adv=`}
            {adv}
            {`&cid=REPLACE_WITH_CID_PARAM&payout=`}
            {payout}
            {`&txid=`}
            {goal}
            <wbr />
            {`&currency=`}
            {currency}
            {`&geo=REPLACE_WITH_GEO`}
          </div>
          <div>
            <button onClick={this.clickCopyButton.bind(this)}>Copy</button>
          </div>
        </div>
      ) : (
        <div>Please fill in the mandatory details above!</div>
      );
    return (
      <div>
        <MuiThemeProvider>
          <>
            <div id="div">
              <SIDEBAR />
              <Toolbar style={styleToolbar}>
                <img src={Logo} style={styleLogoImg} alt="logo" />
                <h3>Postback Generator</h3>
                <ReactTooltip />

                <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => this.handleLogout()} />
              </Toolbar>
            </div>
            <div>
              <div style={{ textAlign: 'start', marginTop: 40, marginLeft: 100 }}>
                <div style={{ color: 'orange' }}>
                  Pass Every Postback (pep)*:{' '}
                  <select
                    style={{ color: 'orange', marginBottom: '4px', marginLeft: '10px' }}
                    data-type="domain"
                    data-qp-key="undefined"
                    onChange={this.changePEP.bind(this)}
                  >
                    <option defaultValue={PEP_STATES.NOT_SELECTED}></option>
                    <option value={PEP_STATES.TRUE}>(pep=y) Don&apos;t filter, everything should be passed on</option>
                    <option value={PEP_STATES.FALSE}>
                      Only postback with payments to Binom (Affise and our API offer are never filtered!)
                    </option>
                  </select>
                </div>
                <br />
                <div style={{ color: 'red' }}>
                  Tracker*:{' '}
                  <select
                    style={{ color: 'red', marginBottom: '4px', marginLeft: '10px' }}
                    data-type="domain"
                    data-qp-key="undefined"
                    onChange={this.changeTracker.bind(this)}
                  >
                    <option defaultValue=""></option>
                    <option value="b">Binom</option>
                    <option value="a">Affise</option>
                    <option value="p">Our API offer</option>
                    <option value="v">Voluum</option>
                  </select>
                </div>
                <br />
                <div style={{ color: 'green' }}>
                  Advertiser*:{' '}
                  <input
                    style={{ color: 'green', marginBottom: '4px', marginLeft: '10px' }}
                    onChange={this.changeAdv.bind(this)}
                  />
                </div>
                <br />
                <div style={{ color: 'blue' }}>
                  Currency:{' '}
                  <select
                    style={{ color: 'blue', marginBottom: '4px', marginLeft: '10px' }}
                    data-type="domain"
                    data-qp-key="undefined"
                    onChange={this.changeCurrency.bind(this)}
                  >
                    <option defaultValue={CURRENCY_DEFAULT_VALUE}></option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                  </select>
                </div>
                <br />
                <div style={{ color: 'purple' }}>
                  Goal Name:{' '}
                  <input
                    style={{ color: 'purple', marginBottom: '4px', marginLeft: '10px' }}
                    onChange={this.changeGoal.bind(this)}
                  />
                </div>
                <br />
                <div style={{ color: 'teal' }}>
                  Payout Amount:{' '}
                  <input
                    id="payoutAmountSelector"
                    type="number"
                    style={{ color: 'teal', marginBottom: '4px', marginLeft: '10px' }}
                    onChange={this.changePayout.bind(this)}
                  />
                  {/* <button onClick={() => {
                    console.log(document.getElementById('payoutAmountSelector')!.nodeValue);
                    document.getElementById('payoutAmountSelector')!.nodeValue = null;
                    console.log(document.getElementById('payoutAmountSelector')!.nodeValue);
                    this.setState({ payout: '' });

                  }}>Clear</button> */}
                </div>
                <br />
                <br />
                <br />
                {linkComponent}
              </div>
            </div>
          </>
        </MuiThemeProvider>
      </div>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh: CSSProperties = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};
const styleToolbar = {
  marginTop: -30,
};
const resultStyle: CSSProperties = {
  overflow: 'hidden',
  wordWrap: 'break-word',
  width: '80%',
};

// const styleLoaderImg = {
//   width: 70,
//   height: 70,
//   // marginLeft: 550
// }
