import React from 'react';
import PageNotFound from './page-not-found.jpg';
import SIDEBAR from './Sidebars';
import Toolbar from 'material-ui/Toolbar';
import Logo from './logo_app.png';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import Firebase from './Firebase';

interface ICurrentProps {}

interface ICurrentState {}

export default class SiteDuplicator extends React.Component<ICurrentProps, ICurrentState> {
  handleLogout() {
    Firebase.logout();
  }

  render() {
    return (
      <div>
        <MuiThemeProvider>
          <div id="div">
            <SIDEBAR />
            <Toolbar style={styleToolbar}>
              <img src={Logo} style={styleLogoImg} alt="logo" />
              <ReactTooltip />

              <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={event => this.handleLogout()} />
            </Toolbar>
          </div>
        </MuiThemeProvider>
        <div>
          <img src={PageNotFound} style={styleNotFound} alt="PageNotFound" />
        </div>
      </div>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%',
};

const styleRefresh = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
};

const styleNotFound = {
  width: '50%',
  height: 300,
  marginLeft: 300,
  marginTop: 20,
};

const styleToolbar = {
  marginTop: -30,
};
