import json_stringify_safe from 'json-stringify-safe';

interface ICacheObj {
  timestamp: number;
  value: string;
  type: string;
}

function isDebug() {
  return typeof Storage !== 'undefined' && localStorage.getItem('dd');
}

function consoleLog(str: string) {
  debugLog(str);
}

function debugLog(str: string) {
  if (isDebug()) {
    console.log(str);
  }
}

function consoleError(str: string) {
  console.error(str);
}

function setCache(key: string, value: string, forSessionStorage: boolean = false) {
  try {
    if (typeof Storage !== 'undefined') {
      const cacheObj: ICacheObj = {
        value: value,
        timestamp: new Date().getTime(),
        type: typeof value,
      };

      const item = JSON.stringify(cacheObj);
      if (forSessionStorage) {
        sessionStorage.setItem(key, item);
      } else {
        localStorage.setItem(key, item);
      }
    }
  } catch (e) {
    consoleError(`setCache - Error: ${convertToString(e)}`);
  }
}

function getCache(key: string, deltaInMilliseconds: number) {
  if (typeof Storage !== 'undefined') {
    let data = sessionStorage.getItem(key);
    let isTakenFromSession = true;

    if (!data) {
      data = localStorage.getItem(key);
      isTakenFromSession = false;
    }

    if (data) {
      const cacheObj: ICacheObj = JSON.parse(data);
      const isExpired = deltaInMilliseconds < new Date().getTime() - cacheObj.timestamp;

      debugLog(
        `${key} - found${isTakenFromSession ? ' in session' : ''}!${
          isExpired
            ? ` But expired ${(new Date().getTime() - cacheObj.timestamp) / 1000} seconds ago, and will be deleted...`
            : ''
        }`
      );

      if (isExpired) {
        if (isTakenFromSession) {
          sessionStorage.removeItem(key);
        } else {
          localStorage.removeItem(key);
        }
      }
      return {
        value: cacheObj.value,
        isExpired: isExpired,
        timestamp: new Date(cacheObj.timestamp),
      };
    } else {
      debugLog(key + ' - not found');
      return null;
    }
  } else {
    debugLog(`${key} - Storage not supported`);
    return null;
  }
}

function convertToString(value: unknown, shouldAddSpaceToJson = true): string {
  const isError = (obj: unknown): boolean => {
    return Object.prototype.toString.call(obj) === '[object Error]';
    // return obj && obj.stack && obj.message && typeof obj.stack === 'string'
    //        && typeof obj.message === 'string';
  };

  try {
    switch (typeof value) {
      case 'string':
      case 'boolean':
      case 'number':
      default:
        return '' + value;

      case 'undefined':
        return '';

      case 'object':
        if (value == null) {
          return '';
        } else if (isError(value)) {
          const error1: Error = value as Error;
          return error1.stack || error1.message;
        } else {
          if (shouldAddSpaceToJson) {
            return json_stringify_safe(value, null, 2);
          } else {
            return json_stringify_safe(value);
          }
        }
    }
  } catch (e) {
    return '' + value;
  }
}

function intersectionOfArrays(array1: unknown[], array2: unknown[]): unknown[] {
  if (!array1 || !array2) {
    return [];
  } else {
    return array1.filter((item: unknown) => array2.includes(item));
  }
}

export function isValidDate(date: unknown): boolean {
  const fn = 'utils.isValidDate';
  try {
    // https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date#answer-44198641
    return (
      Boolean(date) &&
      Object.prototype.toString.call(date) === '[object Date]' &&
      !isNaN(Number(date)) &&
      !isNaN((date as Date).getTime())
    );
  } catch (e) {
    consoleError(`${fn} - Error: ${convertToString(e)}. Returning 'false'.`);
    return false;
  }
}

export function verifyLeadingZeros(dateStr: string | Date | number): string {
  const OCT = 9;
  const tmpDate = new Date(dateStr);
  const m = (tmpDate.getUTCMonth() >= OCT ? '' : '0') + (tmpDate.getUTCMonth() + 1);
  const d = (tmpDate.getUTCDate() >= 10 ? '' : '0') + tmpDate.getUTCDate();
  return tmpDate.getUTCFullYear() + '-' + m + '-' + d;
}

export default {
  isDebug,
  consoleLog,
  debugLog,
  consoleError,
  setCache,
  getCache,
  convertToString,
  intersectionOfArrays,
};
