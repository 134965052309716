import utils from './utils';

export const MINUTE_IN_MILLISECOND = 1000 * 60; // A minute in milliseconds
export const HOUR_IN_MILLISECOND = MINUTE_IN_MILLISECOND * 60; // An hour in milliseconds
export const DAY_IN_MILLISECOND = HOUR_IN_MILLISECOND * 24; // 24 hours in milliseconds

export function beginningOfAMonth(date: Date | number): Date {
  const _parsedDate: Date = new Date(date);
  return new Date(Date.UTC(_parsedDate.getUTCFullYear(), _parsedDate.getUTCMonth(), 1));
}

export function beginningOfThisMonth(): Date {
  return beginningOfAMonth(new Date());
}

export function endOfAMonth(date: Date): Date {
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 1));
}

function isValidDate(date: unknown): boolean {
  const fn = 'isValidDate';
  try {
    // https://stackoverflow.com/questions/643782/how-to-check-whether-an-object-is-a-date#answer-44198641
    return (
      Boolean(date) &&
      Object.prototype.toString.call(date) === '[object Date]' &&
      !isNaN(Number(date)) &&
      !isNaN((date as Date).getTime())
    );
  } catch (e) {
    console.error(`${fn} - Error: ${utils.convertToString(e)}. Returning 'false'.`);
    return false;
  }
}

export function outputDateOnlyWithLeadingZeros(dateStr: string | Date | number): string {
  const OCT = 9;
  const tmpDate = new Date(dateStr);
  const m = (tmpDate.getUTCMonth() >= OCT ? '' : '0') + (tmpDate.getUTCMonth() + 1);
  const d = (tmpDate.getUTCDate() >= 10 ? '' : '0') + tmpDate.getUTCDate();
  return tmpDate.getUTCFullYear() + '-' + m + '-' + d;
}

/**
 * Converts a Date object to a string containing only the date, with leading zeros whenever needed.
 * If the Date is exactly on midnight UTC, then the previous date will be outputted.
 *
 * Examples:
 *
 * Date('2021-11-06T21:10:25.625Z') => '2021-11-06'
 *
 * Date('2021-11-06T00:00:00.000Z') => '2021-11-05'
 *
 * This is helpful in ranges, for example this range:
 *
 * Date('2021-11-06T00:00:00.000Z') - Date('2021-11-08T00:00:00.000Z')
 *
 * Is outputted like this, if this function is used (only) on the end date:
 *
 * '2021-11-06' - '2021-11-07'
 *
 * which is the right way to display it.
 *
 * @param endDate The timestamp
 * @returns A string in the following format: "2000-12-31"
 */
export function convertEndTimestampToEndDate(endDate: Date): string {
  if (isValidDate(endDate)) {
    const beginningOfTheDay = new Date(endDate.toISOString().substring(0, 10));
    let resultString = endDate;
    if (beginningOfTheDay.getTime() == endDate.getTime()) {
      resultString = new Date(endDate.getTime() - DAY_IN_MILLISECOND);
    }
    return outputDateOnlyWithLeadingZeros(resultString);
  } else {
    throw new Error(`endDate is not a valid date: ${endDate}!`);
  }
}

export function isRoundedHour(date: Date): boolean {
  return date.getTime() == new Date(date).setUTCMinutes(0, 0, 0);
}

export function convertDateStringToUTCDate(date: string): Date {
  return new Date(date);
}

export function addDaysToDate(date: Date, days: number) {
  const newDate = new Date(date);
  return new Date(newDate.setDate(newDate.getDate() + days));
}

export function startOfToday() {
  return new Date(new Date().setHours(0, 0, 0, 0));
}

export function endOfToday() {
  return new Date(new Date().setHours(23, 59, 59, 999));
}

export function startOfThisMonth() {
  return new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0));
}

export function endOfThisMonth() {
  return new Date(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).setHours(23, 59, 59, 999));
}

export function startOfLastMonth() {
  return new Date(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).setHours(0, 0, 0, 0));
}

export function endOfLastMonth() {
  return new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).setHours(0, 0, 0, 0));
}

export function startOfThisYear() {
  return new Date(new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0));
}

export function endOfThisYear() {
  return new Date(new Date(new Date().getFullYear(), 11, 31).setHours(23, 59, 59, 999));
}

export function startOfLastNDays(days: number) {
  return new Date(new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - days));
}

export function endOfLastNDays(days: number) {
  return new Date(new Date(new Date().setHours(23, 59, 59, 999)).setDate(new Date().getDate() - days));
}
