import React, { CSSProperties, MouseEventHandler } from 'react';
import Sidebar from 'react-sidebar';
import { MdClose } from 'react-icons/md';
import { GoThreeBars } from 'react-icons/go';
import Firebase from './Firebase';
import * as AuthorizationRoles from './AuthorizationRoles';
import CompanyLogo from './company-logo.png';
import ItemImg from './item-img.png';
import { style } from 'glamor';

interface ICurrentProps {
  history?: string[];
}
interface ICurrentState {
  sidebarOpen: boolean;
  screenState: AuthorizationRoles.IScreenState;
}

export default class Sidebars extends React.Component<ICurrentProps, ICurrentState> {
  constructor(props: Readonly<ICurrentProps>) {
    super(props);
    this.state = {
      sidebarOpen: false,
      screenState: AuthorizationRoles.DEFAULT_SCREEN_STATE,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    AuthorizationRoles.calcUserScreenState().then(screenState => {
      this.setState({
        screenState: screenState,
      });
    });
  }

  onSetSidebarOpen(open: boolean): void {
    this.setState({ sidebarOpen: open });
  }

  handleLogout(): void {
    Firebase.logout().then(() => {
      this.props.history && this.props.history.push('/login');
    });
  }

  handleDailyReport(): void {
    window.open('https://docs.google.com/spreadsheets/d/1cCksQ7XXJcuAWbFzVLqPXcmoM_4dGEsEJ69jHohVBDw/edit', '_blank');
  }

  sidebarItem(
    name: string,
    visible: boolean,
    hrefOrOnClick: string | MouseEventHandler<HTMLAnchorElement>,
    icon: string,
    newTab = false
  ): JSX.Element | null {
    if (!visible) {
      return null;
    }

    const _href = typeof hrefOrOnClick == 'string' ? hrefOrOnClick : '';
    const onClickFunc = typeof hrefOrOnClick == 'string' ? undefined : hrefOrOnClick;

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={_href}
        onClick={onClickFunc}
        // className={styles.hoverButton}
        rel={newTab ? 'noopener noreferrer' : undefined}
        target={newTab ? '_blank' : undefined}
        style={styleOfContainerOfASingleItem}
      >
        {/* <div
        // className={styles.redHoverButton}
      > */}
        <img src={icon} style={iconStyle} alt="ItemImg" />
        <div style={textStyle}>
          <b>{name}</b>
        </div>
        {/* </div> */}
      </a>
    );
  }

  render(): JSX.Element {
    return (
      <Sidebar
        sidebar={
          <div>
            <MdClose onClick={() => this.onSetSidebarOpen(false)} style={styleMdClose} />
            <img src={CompanyLogo} alt="logo" />
            <div
              style={styleOfContainerOfAllItems}
              // className={styles.redHoverButton}
            >
              {/* <img src={ItemImg} style={iconStyle} alt="ItemImg" /> */}
              {this.sidebarItem('Tracker', this.state.screenState.pages.tracker, '/#/tracker', ItemImg)}
              {this.sidebarItem('Crudool (current)', this.state.screenState.pages.performanceDashboard, '/', ItemImg)}
              {this.sidebarItem(
                'Crudool (future version)',
                this.state.screenState.pages.performanceDashboard,
                '/#/new',
                ItemImg
              )}
              {this.sidebarItem(
                'SAMI Incidents',
                this.state.screenState.pages.SAMI_Incidents,
                '/#/sami_incidents',
                ItemImg
              )}
              {this.sidebarItem(
                'Postback Generator',
                this.state.screenState.pages.postbackGenerator,
                '/#/postbackGenerator',
                ItemImg
              )}
              {this.sidebarItem(
                'Binom Converter',
                this.state.screenState.pages.binomConverter,
                '/#/binom-campaign-converter',
                ItemImg
              )}
              {this.sidebarItem(
                'Site Duplicator',
                this.state.screenState.pages.websiteDuplicator,
                '/#/siteDuplicator',
                ItemImg
              )}
              {this.sidebarItem('DB Content', this.state.screenState.pages.dbContent, '/#/dbContent', ItemImg)}
              {this.sidebarItem(
                'Daily Report',
                this.state.screenState.pages.dailyReport,
                'https://docs.google.com/spreadsheets/d/1cCksQ7XXJcuAWbFzVLqPXcmoM_4dGEsEJ69jHohVBDw/edit',
                ItemImg,
                true
              )}
              {this.sidebarItem('Users', this.state.screenState.pages.userManagement, '/#/userManagement', ItemImg)}
              {this.sidebarItem('Logout', true, () => this.handleLogout(), ItemImg)}
            </div>
          </div>
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={{
          root: {
            position: undefined,
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            overflow: 'hidden',
          },
          sidebar: {
            zIndex: '2',
            position: 'absolute',
            top: '0',
            bottom: '0',
            transition: 'transform .3s ease-out',
            // WebkitTransition: "-webkit-transform .3s ease-out",
            willChange: 'transform',
            overflowY: 'auto',
            background: '#8992a9',
            width: '250px',
          },
          content: {
            position: undefined,
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            overflowY: 'auto',
            // WebkitOverflowScrolling: "touch",
            transition: 'left .3s ease-out, right .3s ease-out',
          },
          overlay: {
            zIndex: '1',
            position: 'fixed',
            top: '0',
            left: '0',
            right: '0',
            bottom: '0',
            opacity: '0',
            visibility: 'hidden',
            transition: 'opacity .3s ease-out, visibility .3s ease-out',
            backgroundColor: 'rgba(0,0,0,.3)',
          },
          dragHandle: {
            zIndex: '1',
            position: 'fixed',
            top: '0',
            bottom: '0',
          },
        }}
      >
        <GoThreeBars onClick={() => this.onSetSidebarOpen(true)} style={styleGo} />
      </Sidebar>
    );
  }
}

const styleMdClose = {
  width: '10%',
  height: '10%',
  cursor: 'pointer',
  color: 'white',
};

const styleGo = {
  width: 25,
  height: 25,
  cursor: 'pointer',
};

const styleOfContainerOfAllItems = {
  cursor: 'pointer',
};

const textStyle = {
  marginBottom: 20,
  marginLeft: 60,
};

const styleOfContainerOfASingleItem: CSSProperties = {
  borderBottom: '1px solid #9ca4b8',
  color: 'black',
  cursor: 'pointer',
  boxSizing: 'border-box',
  display: 'block',
};

const iconStyle = {
  width: 20,
  height: 14,
  marginBottom: -44,
  marginLeft: 30,
};

// const styles = {
//   redHoverButton: style({

//   }),
//   hoverButton: style({
//     ":hover": {
//       textDecorationColor: 'black',
//     }
//   })
// }
